import { DateOnly } from '../utility';

export interface IOfferReservationDto {
  id: string;

  offerId: string;

  contactMail: string;
  contactBirthday: DateOnly | null;
  contactPhone: string | null;
  contactTitle: string | null;
  contactFirstname: string;
  contactLastname: string;

  contactStreet: string;
  contactStreetNr: string;
  contactPostal: string;
  contactCity: string;
  contactCountry: string;

  contactLanguage: string | null;

  acceptedCost: number;
  acceptedDeposit: number;

  agbAccepted: boolean;
  privacyPolicyAccepted: boolean;
  newsletter: boolean;

  tracked: boolean;
  needsDeposit: boolean;

  message: string | null;
}

export const EmptyOfferReservationDto: IOfferReservationDto = {
  id: '00000000-0000-0000-0000-000000000000',

  offerId: '',

  contactMail: '',
  contactBirthday: new DateOnly(),
  contactPhone: '',
  contactTitle: null,
  contactFirstname: '',
  contactLastname: '',

  contactStreet: '',
  contactStreetNr: '',
  contactPostal: '',
  contactCity: '',
  contactCountry: '',

  contactLanguage: null,

  acceptedCost: 0,
  acceptedDeposit: 0,

  agbAccepted: false,
  privacyPolicyAccepted: false,
  newsletter: false,

  tracked: false,
  needsDeposit: false,

  message: null,
};

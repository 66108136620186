import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export interface StyledLinkProps {
  onClick?: () => void;

  design?: 'default' | 'primary' | 'error';
  target?: string;
  href: string;
  disabled?: boolean;
  classNames?: string;
  tooltip?: string;

  children?: React.ReactNode;
}

export function StyledLink(props: StyledLinkProps) {
  const { href, onClick, children, disabled } = props;

  const isPrimary = props.design && props.design === 'primary';
  const isError = props.design && props.design === 'error';

  const isDefault = !isPrimary && !isError;

  const tooltipId = uuidv4();

  return (
    <div className="inline-flex">
      {props.tooltip && <Tooltip id={tooltipId}></Tooltip>}

      <a
        href={href}
        onClick={() => onClick && onClick()}
        data-tooltip-id={tooltipId}
        data-tooltip-content={props.tooltip}
        target={props.target}
        className={classNames(
          'flex rounded-md border text-sm font-medium px-4 py-2 shadow-sm select-none', //frame + alignments
          'disabled:opacity-50', //effects
          !isDefault && 'border-transparent', //default on desgined buttons
          !isDefault && 'focus:outline-none focus:ring-2 focus:ring-offset-2', //effects on designed buttons
          /** STYLE */
          isPrimary && 'bg-primary-600 text-white',
          isPrimary &&
            !disabled &&
            'hover:bg-primary-700 focus:ring-primary-700',
          isError && 'bg-red-800 text-white',
          isError && !disabled && 'hover:bg-red-900 focus:ring-red-700',
          isDefault && 'bg-white text-black border-gray-300',
          isDefault && !disabled && 'hover:bg-gray-50',
          props.classNames && props.classNames
        )}
      >
        {children}
      </a>
    </div>
  );
}

export default StyledLink;

import { IPromotionDto } from './IPromotionDto';

export interface IVoucherDto {
  id: number;

  contactMail: string;
  contactPhone: string | null;
  contactTitle: string | null;
  contactFirstname: string;
  contactLastname: string;

  contactStreet: string;
  contactStreetNr: string;
  contactPostal: string;
  contactCity: string;
  contactCountry: string;

  contactLanguage: string | null;

  promotion: IPromotionDto | null;

  code: string | null;
  title: string;
  text: string;
  value: number;
}

export const EmptyVoucherDto: IVoucherDto = {
  id: 0,

  contactMail: '',
  contactPhone: null,
  contactTitle: null,
  contactFirstname: '',
  contactLastname: '',

  contactStreet: '',
  contactStreetNr: '',
  contactPostal: '',
  contactCity: '',
  contactCountry: '',

  contactLanguage: null,
  promotion: null,

  code: null,
  title: '',
  text: '',
  value: 100.0,
};

import { AtSymbolIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { StyledLink } from '../ui';
import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation('offer-package');

  return (
    <footer className="flex flex-col justify-center text-center items-center gap-4 print:hidden">
      <img
        src="https://cdn.mandira-ayurveda.at/images/claim/2024/dark_cyan_twoline.png"
        alt="Claim"
        width="100px"
        height="100px"
      ></img>

      <div className="flex flex-col justify-center text-center gap-2">
        <a
          href="mailto:info@mandira-ayurveda.at"
          className="underline hover:text-primary-700"
        >
          info@mandira-ayurveda.at
        </a>
        <a href="tel:+4333332801" className="underline hover:text-primary-700">
          +43 3333 2801
        </a>
      </div>

      <address className="text-sm">
        Wagerberg 120
        <br />
        8271 Bad Waltersdorf
        <br />
        Österreich
      </address>
    </footer>
  );
}

export default Footer;

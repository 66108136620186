import { useTranslation } from 'react-i18next';

export function Yoga() {
  const { i18n } = useTranslation('offer-package');

  if (i18n.language === 'de') {
    return (
      <div className="bg-white border shadow-md rounded-md overflow-clip">
        <header className="bg-primary-600 px-4 py-5">
          <h2 className="text-white font-bold">Bewegung & Regeneration</h2>
        </header>
        <div className="grid md:grid-cols-3">
          <div className="p-4">
            <img
              src="https://cdn.mandira-ayurveda.at/images/resort/2020/yoga.jpg"
              alt="Yoga"
            ></img>
          </div>

          <div className="p-4 md:col-span-2">
            <p>
              Unser Körper ist Bindeglied zwischen Geist und Umwelt. Unseren
              Körper gesund und flexibel zu erhalten, ist eine essenzielle Säule
              der ganzheitlichen Gesundheit. Mit unserem Bewegungs- &
              Regenerationsprogramm steigern Sie Ihre Fitness, nähren die Seele
              und bringen Geist und Körper in Balance.
            </p>

            <p className="mt-2">Sie erwartet z.B.:</p>

            <ul className="list list-disc ms-5">
              <li>
                Yoga & Meditation mit unseren Yoga- und Mediationsexperten:
                Atemübungen (Pranayama), Achtsamkeitsstunden
              </li>
              <li>
                Bewegungs- und Entspannungsprogramm mit unseren
                Bewegungsexperten: Heilthermal Smovey Aquafit, Faszientraining,
                Rückenfit u. v. m.
              </li>
              <li>Training im Fitnessraum</li>
              <li>Verleih von Nordic-Walking-Stöcken und Smovey-Ringen</li>
              <li>
                Fahrrad-Verleih (E-Bikes gegen Gebühr) inkl. Radhelm und
                Radkarte
              </li>
              <li>Bücherecke und E-Book-Reader-Verleih</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border shadow-md rounded-md overflow-clip">
      <header className="bg-primary-600 px-4 py-5">
        <h2 className="text-white font-bold">Exercise & regeneration</h2>
      </header>
      <div className="grid md:grid-cols-3">
        <div className="p-4">
          <img
            src="https://cdn.mandira-ayurveda.at/images/resort/2020/yoga.jpg"
            alt="Yoga"
          ></img>
        </div>

        <div className="p-4 md:col-span-2">
          <p>
            Our body is the link between our mind and our environment. Keeping
            our body healthy and flexible is an essential pillar of holistic
            health. of holistic health. With our exercise & regeneration program
            regeneration program, you can increase your fitness, nourish your
            soul and bring mind and body into balance.
          </p>

          <p className="mt-2">You can expect e.g:</p>

          <ul className="list list-disc ms-5">
            <li>
              Yoga & meditation with our yoga and mediation experts: Breathing
              exercises (pranayama), mindfulness classes
            </li>
            <li>
              Exercise and relaxation program with our exercise experts: Healing
              thermal Smovey Aquafit, fascia training, back fitness and much
              more.
            </li>
            <li>Training in the fitness room</li>
            <li>Nordic walking poles and Smovey rings for hire</li>
            <li>
              Bike rental (e-bikes for a fee) incl. bike helmet and bike map
            </li>
            <li>Book corner and e-book reader rental</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Yoga;

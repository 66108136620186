import { Component } from 'react';
import { INotificationDto, INotificationService } from './../services';
import { Subject, takeUntil } from 'rxjs';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';

export interface IOverlayStatsDto {
  items: INotificationDto[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOverlayPropsDto {
  service: INotificationService;
}

export class Overlay extends Component<IOverlayPropsDto, IOverlayStatsDto> {
  private _destory = new Subject<void>();

  componentWillUnmount(): void {
    this._destory.next();
    this._destory.complete();
  }

  componentDidMount(): void {
    this.props.service.notifications$.pipe(takeUntil(this._destory)).subscribe({
      next: (data) => {
        this.setState({
          items: data,
        });
      },
    });
  }

  constructor(props: IOverlayPropsDto) {
    super(props);

    this.state = {
      items: [],
    };
  }

  deleteItem(selectedItem: INotificationDto): void {
    this.props.service.deleteNotification(selectedItem);
  }

  render() {
    const { items } = this.state;

    return (
      <div
        id="notification-overlay"
        aria-live="assertive"
        className="z-[999] pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 print:hidden"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

          {items &&
            items.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {item.type === 'success' && (
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        )}
                        {item.type === 'error' && (
                          <ExclamationCircleIcon
                            className="h-6 w-6 text-red-400"
                            aria-hidden="true"
                          />
                        )}
                        {item.type === 'failure' && (
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {item.title}
                        </p>
                        {item.description && (
                          <p className="mt-1 text-sm text-gray-500">
                            {item.description}
                          </p>
                        )}
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => this.deleteItem(item)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default Overlay;

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { INotificationService } from '../../services';
import {
  ICheckRoomsAvailiableResponseDto,
  IReservationRoomDto,
  IRoomCategoryDto,
} from '../../model';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import {
  RoomCalc,
  gtmEvent,
  removeFirstOccurrenceByProperty,
} from '../../utility';
import {
  IStepperContext,
  ITranslationDetailProps,
  StepperContext,
  StyledButton,
  StyledDialog,
  TranslationDetail,
} from '../../ui';
import classNames from 'classnames';
import { MapRoomToAnalyticsItem } from '../../mapping';
import { IGaCartEventDto } from '../../model/analytics';

export interface IRoomPickerProps {
  notificationService: INotificationService;
  roomCategoryAvailiableList: ICheckRoomsAvailiableResponseDto[];
}

export function RoomPicker(props: IRoomPickerProps) {
  const { t, i18n } = useTranslation();
  const methods = useFormContext();
  const stepperContext = useContext<IStepperContext>(StepperContext);

  const personCountWatch: number = methods.watch('personCount');
  const roomCountWatch: number = methods.watch('roomCount');
  const roomWatch: IReservationRoomDto[] = methods.watch('roomList');

  const [modalContent, setModalContent] = useState<ITranslationDetailProps>();

  const [fullRoomOccupancyList, setFullRoomOccupancyList] = useState<number[]>(
    []
  );
  const [roomOccupancyList, setRoomOccupancyList] = useState<number[]>([]);

  const onNext = () => {
    if (stepperContext.onNextStep) {
      stepperContext.onNextStep();
    }
  };

  useEffect(() => {
    let calcedRoomOccupancies = RoomCalc(personCountWatch, roomCountWatch);
    setFullRoomOccupancyList([...calcedRoomOccupancies]);

    roomWatch.forEach((element) => {
      calcedRoomOccupancies = removeFirstOccurrenceByProperty(
        calcedRoomOccupancies,
        (number) => number === element.occupancy
      );
    });

    setRoomOccupancyList(calcedRoomOccupancies);
  }, [roomCountWatch, personCountWatch, roomWatch]);

  //#region functions

  const trackAddRoom = (dto: IRoomCategoryDto, occupancy: number) => {
    const existingCount = countRooms(dto);

    const foundRoomAvailability = props.roomCategoryAvailiableList.find(
      (e) => e.roomCategory.id === dto.id
    );

    if (
      foundRoomAvailability &&
      existingCount + 1 > foundRoomAvailability.numberOfRooms
    ) {
      return;
    }

    if (roomWatch.length < roomCountWatch && foundRoomAvailability) {
      const items = [MapRoomToAnalyticsItem(foundRoomAvailability, occupancy)];

      const eventProps: IGaCartEventDto = {
        currency: 'EUR',
        value:
          items
            .map((e) => e.price)
            .filter((e) => e)
            .reduce((v, c) => v! + c!, 0.0) ?? 0,
        items: items,
      };

      gtmEvent('add_to_cart', undefined, eventProps);
    }
  };

  const trackRemoveRoom = (dto: IRoomCategoryDto) => {
    const foundCurrent = roomWatch.find((x) => x.roomCategory.id === dto.id);
    if (foundCurrent) {
      const foundRoomAvailability = props.roomCategoryAvailiableList.find(
        (e) => e.roomCategory.id === dto.id
      );

      if (foundRoomAvailability) {
        const items = [
          MapRoomToAnalyticsItem(foundRoomAvailability, foundCurrent.occupancy),
        ];

        const eventProps: IGaCartEventDto = {
          currency: 'EUR',
          value:
            items
              .map((e) => e.price)
              .filter((e) => e)
              .reduce((v, c) => v! + c!, 0.0) ?? 0,
          items: items,
        };

        gtmEvent('remove_from_cart', undefined, eventProps);
      }
    }
  };

  const setRoom = (dto: IRoomCategoryDto) => {
    const calcedRoomOccupancies = RoomCalc(personCountWatch, roomCountWatch);

    if (calcedRoomOccupancies.length === 1) {
      if (roomWatch.length > 0) {
        trackRemoveRoom(roomWatch[0].roomCategory);
      }

      trackAddRoom(dto, calcedRoomOccupancies[0]);

      methods.setValue('roomList', [
        { id: 0, occupancy: calcedRoomOccupancies[0], roomCategory: dto },
      ]);
    }
  };

  const addRoom = (dto: IRoomCategoryDto, occupancy: number) => {
    const existingCount = countRooms(dto);

    const foundRoomAvailability = props.roomCategoryAvailiableList.find(
      (e) => e.roomCategory.id === dto.id
    );

    if (
      foundRoomAvailability &&
      existingCount + 1 > foundRoomAvailability.numberOfRooms
    ) {
      props.notificationService.createNotification({
        type: 'normal',
        title: 'Es stehen keine weiteren Zimmer dieser Kategorie zur Verfügung',
        hideAfterSeconds: 10,
      });

      return;
    }

    const currentRooms = roomWatch as IReservationRoomDto[];

    if (currentRooms.length < roomCountWatch && foundRoomAvailability) {
      trackAddRoom(dto, occupancy);

      methods.setValue('roomList', [
        ...currentRooms,
        { id: 0, occupancy, roomCategory: dto },
      ]);
    }
  };

  const removeRoom = (dto: IRoomCategoryDto) => {
    let currentRooms: IReservationRoomDto[] = [...roomWatch];

    const foundCurrent = currentRooms.find((x) => x.roomCategory.id === dto.id);
    if (foundCurrent) {
      trackRemoveRoom(dto);
    }

    currentRooms = removeFirstOccurrenceByProperty(
      currentRooms,
      (e) => e.roomCategory.id === dto.id
    );

    methods.setValue('roomList', currentRooms);
  };

  const cannotAddRooms = () => roomWatch.length >= roomCountWatch;
  const countRooms = (dto: IRoomCategoryDto) =>
    roomWatch.filter((e) => e.roomCategory.id === dto.id).length;

  //#endregion functions

  return (
    <div className="space-y-2">
      <StyledDialog
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent && (
          <TranslationDetail
            text={modalContent.text}
            imageLinks={modalContent.imageLinks}
          ></TranslationDetail>
        )}
      </StyledDialog>

      <h2 className="text-gray-800 text-lg">
        {t('reservation.room.title')}
        {': '}
      </h2>

      <span className="font-bold">
        {roomOccupancyList &&
          roomOccupancyList[0] === 1 &&
          t('reservation.room.single')}
        {roomOccupancyList &&
          roomOccupancyList[0] === 2 &&
          t('reservation.room.double')}
      </span>

      <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:gap-x-6 lg:gap-8">
        {props.roomCategoryAvailiableList
          .map((room) => {
            return {
              ...room,
              translation: room.roomCategory.roomCategoryTranslationList.find(
                (f) => f.langCode === i18n.language
              ),
            };
          })
          .map((room) => (
            <div
              key={room.roomCategory.id}
              className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg relative h-96"
            >
              {room.roomCategory.imageLinks ? (
                <img
                  src={room.roomCategory.imageLinks[0]}
                  alt={room.translation?.title}
                  className="absolute h-full object-cover object-center group-hover:opacity-75"
                />
              ) : (
                <div className="bg-primary-700 h-full w-full absolute">
                  <img
                    src="https://cdn.mandira-ayurveda.at/images/claim/2024/white_twoline.svg"
                    alt="Mandira-Claim"
                    className="h-full w-full p-6 object-fill object-center group-hover:opacity-75"
                  />
                </div>
              )}

              <div
                aria-hidden="true"
                className="absolute h-full w-full bg-gradient-to-b from-transparent to-black opacity-50"
              />

              <div className="absolute py-2 px-1 top-0 flex justify-between items-center w-full text-white">
                <div
                  className={classNames(
                    'px-2 py-1',
                    roomCountWatch > 0 && 'rounded-md backdrop-blur'
                  )}
                >
                  {roomCountWatch > 1 && countRooms(room.roomCategory) > 0 && (
                    <div className="text-2xl">
                      {countRooms(room.roomCategory)}x
                    </div>
                  )}

                  {roomCountWatch === 1 &&
                    countRooms(room.roomCategory) > 0 && (
                      <div className="text-3xl">
                        <CheckCircleIcon className="w-7 h-7"></CheckCircleIcon>
                      </div>
                    )}

                  {countRooms(room.roomCategory) === 0 && <div></div>}
                </div>

                <div className="px-2 py-1 rounded-md backdrop-blur">
                  {((roomOccupancyList &&
                    roomOccupancyList.length > 0 &&
                    roomOccupancyList[0] === 2) ||
                    (roomOccupancyList.length === 0 &&
                      fullRoomOccupancyList.find((e) => e === 2))) && (
                    <div>
                      <p className="text-lg">
                        <UserIcon className="w-4 h-4 inline"></UserIcon>
                        <UserIcon className="w-4 h-4 inline"></UserIcon>
                        {': '}
                        {room.doubleRoomPriceTotal}€
                      </p>
                      {room.doubleDiscountOnline > 1 && (
                        <p className="text-lg">
                          <UserIcon className="w-4 h-4 inline"></UserIcon>
                          <UserIcon className="w-4 h-4 inline"></UserIcon>
                          <span className="text-lg">
                            {`: -${room.doubleDiscountOnline}€`}
                          </span>
                        </p>
                      )}
                    </div>
                  )}

                  {((roomOccupancyList &&
                    roomOccupancyList.length > 0 &&
                    roomOccupancyList[0] === 1) ||
                    (roomOccupancyList.length === 0 &&
                      fullRoomOccupancyList.find((e) => e === 1))) && (
                    <div>
                      <p className="text-lg">
                        <UserIcon className="w-4 h-4 inline"></UserIcon>
                        {': '}
                        {room.singleRoomPriceTotal}€
                      </p>
                      {room.singleDiscountOnline > 1 && (
                        <p className="text-lg">
                          {`-${room.singleDiscountOnline}€`}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="absolute flex items-end h-full w-full">
                <div className="flex flex-col w-full backdrop-blur">
                  <button
                    type="button"
                    className="text-left"
                    onClick={() =>
                      room.translation &&
                      setModalContent({
                        text: room.translation,
                        imageLinks: room.roomCategory.imageLinks,
                      })
                    }
                  >
                    <div className="text-white py-2 px-4">
                      <h2 className="text-lg">{room.translation?.title}</h2>
                      <p className="text-sm">{room.translation?.teaser}</p>
                      <span className="mt-1 underline">
                        {t('reservation.more')}
                      </span>
                    </div>
                  </button>

                  {roomCountWatch === 1 && (
                    <button
                      type="button"
                      className="p-4 hover:bg-primary-600 hover:bg-opacity-50 hover:text-white outline-1 outline outline-white text-white"
                      onClick={() => {
                        setRoom(room.roomCategory);
                      }}
                    >
                      {t('reservation.room.select')}
                    </button>
                  )}

                  {roomCountWatch > 1 && (
                    <div className="w-full grid grid-cols-2">
                      <button
                        type="button"
                        className="p-4 text-lg hover:bg-primary-600 hover:bg-opacity-50 hover:text-white outline-1 outline outline-white text-white"
                        disabled={cannotAddRooms()}
                        onClick={() =>
                          addRoom(room.roomCategory, roomOccupancyList[0])
                        }
                      >
                        +
                      </button>

                      <button
                        type="button"
                        className="p-4 text-lg hover:bg-red-500 hover:bg-opacity-50 hover:text-white outline-1 outline outline-white text-white"
                        disabled={
                          roomWatch.findIndex(
                            (e: IReservationRoomDto) =>
                              e.roomCategory.id === room.roomCategory.id
                          ) === -1
                        }
                        onClick={() => removeRoom(room.roomCategory)}
                      >
                        -
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="grid grid-cols-3 md:flex items-center justify-center gap-2 bottom-0 right-0 p-4 fixed bg-white w-full">
        <StyledButton
          type="button"
          className="w-full md:w-48 md:w-48 h-12 md:h-auto"
          onClick={() =>
            stepperContext.onLastStep && stepperContext.onLastStep()
          }
        >
          <span className="flex justify-center items-center w-full h-full">
            {t('reservation.back')}
          </span>
        </StyledButton>

        <StyledButton
          type="button"
          design="primary"
          rootClassName="w-full col-span-2 md:w-48 md:w-48 h-12 md:h-auto"
          className={classNames(
            'w-full',
            roomCountWatch === roomWatch.length &&
              'outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline'
          )}
          disabled={roomCountWatch !== roomWatch.length}
          onClick={() => onNext()}
        >
          <span className="flex justify-center items-center w-full h-full">
            {t('reservation.forward')}
          </span>
        </StyledButton>
      </div>
    </div>
  );
}

export default RoomPicker;

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  IStepperContext,
  PriceSummary,
  StepperContext,
  StyledButton,
  StyledInput,
  StyledTextarea,
} from '../../ui';
import classNames from 'classnames';
import {
  ICheckRoomsAvailiableResponseDto,
  IReservationCureDto,
  IReservationRoomDto,
  ISeasonCurePriceDto,
} from '../../model';
import { useContext, useEffect, useState } from 'react';
import { DateOnly } from '../../utility';
import { INotificationService } from '../../services';
import { IPromotionDto, PromotionType } from '../../model/IPromotionDto';

export interface IContactPickerProps {
  notificationService: INotificationService;
  roomCategoryAvailiableList: ICheckRoomsAvailiableResponseDto[];
  cures: ISeasonCurePriceDto[];
  promotion: IPromotionDto | null;
  loadPromotionByCode?: (code: string) => void;
}

export function ContactPicker(props: IContactPickerProps) {
  const { t, i18n } = useTranslation();
  const methods = useFormContext();
  const stepperContext = useContext<IStepperContext>(StepperContext);
  const [deposit, setDeposit] = useState<number>(0);
  const [discount, setDiscount] = useState<number>();

  const fromWatch: DateOnly = methods.watch('from');
  const toWatch: DateOnly = methods.watch('to');
  const roomWatch: IReservationRoomDto[] = methods.watch('roomList');
  const cureWatch: IReservationCureDto[] = methods.watch('cureList');
  const costWatch: number = methods.watch('acceptedCost');

  useEffect(() => {
    const newDeposit = Math.ceil(costWatch / 3.0 / 50) * 50;
    setDeposit(newDeposit);
    methods.setValue('acceptedDeposit', newDeposit);
  }, [costWatch]);

  useEffect(() => {
    const totalRooms = roomWatch
      .map((room) => {
        const currentRoom = props.roomCategoryAvailiableList.find(
          (x) => x.roomCategory.id === room.roomCategory.id
        );

        if (room.occupancy === 1) {
          return currentRoom?.singleRoomPriceTotal;
        } else {
          return currentRoom?.doubleRoomPriceTotal;
        }
      })
      .reduce((next, current) => next! + current!, 0);

    const totalCures = cureWatch
      .map((resCure) => {
        return props.cures.find((x) => x.cure.id === resCure.cure.id)
          ?.priceAdult;
      })
      .reduce((next, current) => next! + current!, 0);

    const total = totalRooms! + totalCures!;
    let discount = 0.0;

    if (props.promotion) {
      if (props.promotion.type === PromotionType.fixedAll) {
        discount = props.promotion.value;
      } else if (props.promotion.type === PromotionType.percentageAll) {
        discount = (total * props.promotion.value) / 100.0;
        discount = Math.round(discount * 100.0) / 100.0;
      }

      setDiscount(discount);
    } else {
      setDiscount(undefined);
    }

    methods.setValue('acceptedCost', total - discount);
  }, [roomWatch, cureWatch, props.promotion]);

  const addPromo = (code: string) => {
    props.loadPromotionByCode && props.loadPromotionByCode(code);
  };

  const renderPriceSummary = () => {
    return (
      <PriceSummary
        fromTitle={t('reservation.from.short')}
        from={fromWatch}
        toTitle={t('reservation.to.short')}
        to={toWatch}
        priceRoomTitle={t('reservation.price.room')}
        priceRoom={roomWatch.map((room) => {
          const singleRoomPrice = props.roomCategoryAvailiableList.find(
            (x) => x.roomCategory.id === room.roomCategory.id
          )?.singleRoomPriceTotal;

          const doubleRoomPrice = props.roomCategoryAvailiableList.find(
            (x) => x.roomCategory.id === room.roomCategory.id
          )?.doubleRoomPriceTotal;

          return {
            name: room.roomCategory.roomCategoryTranslationList.find(
              (x) => x.langCode === i18n.language
            )?.title,
            value: room.occupancy === 1 ? singleRoomPrice : doubleRoomPrice,
          } as { name: string; value: number };
        })}
        priceCureTitle={t('reservation.price.cure')}
        priceCure={cureWatch.map((resCure, idx) => {
          return {
            name: resCure.cure.cureTranslationList.find(
              (x) => x.langCode === i18n.language
            )?.title,
            value: props.cures.find((x) => x.cure.id === resCure.cure.id)
              ?.priceAdult,
          } as { name: string; value: number };
        })}
        discountTitle={t('reservation.promo.discount')}
        discountCode={props.promotion?.code}
        discount={discount}
        priceTotalTitle={t('reservation.price.total')}
        priceTotal={costWatch}
        depositTitle={t('reservation.price.deposit')}
        deposit={deposit}
        taxInformation={t('reservation.price.tax')}
        promotionTitle={t('reservation.promo.code')}
        onPromoChange={(code) => addPromo(code)}
      ></PriceSummary>
    );
  };

  return (
    <div className="space-y-2">
      <FormGroup title={t('summary.title')}>
        <div className="flex flex-col sm:col-span-6 2xl:col-span-4">
          {renderPriceSummary()}
        </div>
      </FormGroup>

      <FormGroup title={t('contact.title')} addColon={true}>
        <div className="sm:col-span-3">
          <Controller
            name="contactMail"
            control={methods.control}
            render={(s) =>
              StyledInput({
                ...s,
                title: t('contact.mail'),
                prefix: '✉',
                inputType: 'email',
              })
            }
          />
        </div>

        <div className="sm:col-span-3">
          <Controller
            name="contactPhone"
            control={methods.control}
            render={(s) =>
              StyledInput({
                ...s,
                title: t('contact.call'),
                prefix: '📞',
                placeholder: '+43',
              })
            }
          />
        </div>
      </FormGroup>

      <FormGroup title={t('newsletter.title')}>
        <div className="sm:col-span-6">
          <Controller
            name="newsletter"
            control={methods.control}
            render={(s) => (
              <button
                type="button"
                className={classNames(
                  'rounded-md border p-2 w-full text-left flex gap-2',
                  s.field.value &&
                    'text-green-800 border-green-800 bg-green-50',
                  !s.field.value && 'text-gray-900 border-gray-300 bg-gray-50'
                )}
                onClick={() => s.field.onChange(!s.field.value)}
              >
                {s.field.value ? ' ☑  ' : ' □  '}
                {t('newsletter.message')}
              </button>
            )}
          />
        </div>
      </FormGroup>

      <FormGroup title={t('personal.title')} addColon={true}>
        <div className="sm:col-span-6 grid sm:grid-cols-6">
          <div className="sm:col-span-2">
            <Controller
              name="contactTitle"
              control={methods.control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.salutation'),
                  hint: '(optional)',
                })
              }
            />
          </div>
        </div>

        <div className="sm:col-span-3 lg:col-span-2">
          <Controller
            name="contactFirstname"
            control={methods.control}
            render={(s) =>
              StyledInput({ ...s, title: t('personal.firstname') })
            }
          />
        </div>

        <div className="sm:col-span-3 lg:col-span-2">
          <Controller
            name="contactLastname"
            control={methods.control}
            render={(s) => StyledInput({ ...s, title: t('personal.lastname') })}
          />
        </div>

        <div className="sm:col-span-3">
          <Controller
            name="contactBirthday"
            control={methods.control}
            render={(s) =>
              StyledInput({
                ...s,
                title: t('personal.birthday'),
                inputType: 'date',
                prefix: '🎂',
              })
            }
          />
        </div>

        <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
          <div className="sm:col-span-3 lg:col-span-2">
            <Controller
              name="contactStreet"
              control={methods.control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.street'),
                })
              }
            />
          </div>

          <div className="sm:col-span-2 lg:col-span-1">
            <Controller
              name="contactStreetNr"
              control={methods.control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.number'),
                })
              }
            />
          </div>
        </div>

        <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
          <div className="sm:col-span-2 lg:col-span-1">
            <Controller
              name="contactPostal"
              control={methods.control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.postal'),
                })
              }
            />
          </div>

          <div className="sm:col-span-3 lg:col-span-2">
            <Controller
              name="contactCity"
              control={methods.control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.city'),
                })
              }
            />
          </div>
        </div>

        <div className="sm:col-span-4 lg:col-span-3">
          <Controller
            name="contactCountry"
            control={methods.control}
            render={(s) =>
              StyledInput({
                ...s,
                title: t('personal.country'),
              })
            }
          />
        </div>
      </FormGroup>

      <FormGroup
        title={t('message.title')}
        isOptional={true}
        description={t('message.description')}
      >
        <div className="sm:col-span-6">
          <Controller
            name="message"
            control={methods.control}
            render={(s) => StyledTextarea({ ...s, title: '' })}
          />
        </div>
      </FormGroup>

      <FormGroup title={t('summary.title')}>
        <div className="flex flex-col sm:col-span-6 2xl:col-span-4">
          {renderPriceSummary()}
        </div>

        <div className="sm:col-span-6">
          <Controller
            name="agbAccepted"
            control={methods.control}
            render={(s) => (
              <button
                type="button"
                className={classNames(
                  'rounded-md border p-2 w-full text-left flex gap-2',
                  s.field.value &&
                    'text-green-800 border-green-800 bg-green-50',
                  !s.field.value && 'text-gray-900 border-gray-300 bg-gray-50'
                )}
                onClick={() => s.field.onChange(!s.field.value)}
              >
                {s.field.value ? ' ☑  ' : ' □  '}
                {t('agb.message')}
              </button>
            )}
          />
        </div>

        <div className="sm:col-span-6">
          <Controller
            name="privacyPolicyAccepted"
            control={methods.control}
            render={(s) => (
              <button
                type="button"
                className={classNames(
                  'rounded-md border p-2 w-full text-left flex gap-2',
                  s.field.value &&
                    'text-green-800 border-green-800 bg-green-50',
                  !s.field.value && 'text-gray-900 border-gray-300 bg-gray-50'
                )}
                onClick={() => s.field.onChange(!s.field.value)}
              >
                {s.field.value ? ' ☑  ' : ' □  '}
                {t('privacy-policy.message')}
              </button>
            )}
          />
        </div>

        <div className="sm:col-span-6 flex gap-2">
          <StyledButton
            type="button"
            onClick={() =>
              stepperContext.onLastStep && stepperContext.onLastStep()
            }
          >
            {t('reservation.back')}
          </StyledButton>

          <StyledButton
            type="submit"
            className={classNames(
              methods.formState.isValid &&
                'outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline'
            )}
            disabled={
              !methods.formState.isValid || methods.formState.isSubmitting
            }
            design="primary"
          >
            {t('reservation.book')}
          </StyledButton>
        </div>

        <span className="sm:col-span-6">
          <ul className="list list-inside list-disc">
            <li>{t('reservation.price.arrival')}</li>
            <li>{t('reservation.price.departure')}</li>
            <li>{t('reservation.price.notes')}</li>
            <li>{t('reservation.price.storno')}</li>
          </ul>
        </span>

        <div className="sm:col-span-6 flex flex-col gap-1">
          <span>
            {'* '}
            <a
              href={
                i18n.language === 'de'
                  ? 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agb-hotellerie.pdf'
                  : 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agbh-englisch.pdf'
              }
              className="underline"
              rel="noreferrer"
              target="_blank"
            >
              {t('agb.title')}
            </a>
          </span>
          <span>
            {'** '}
            <a
              href="https://www.mandira-ayurveda.at/datenschutz/"
              className="underline"
              rel="noreferrer"
              target="_blank"
            >
              {t('privacy-policy.title')}
            </a>
          </span>
        </div>
      </FormGroup>
    </div>
  );
}

export default ContactPicker;

import { useEffect, useState } from 'react';
import { combineLatest, from } from 'rxjs';
import { distinctBy } from '../../utility';
import { useTranslation } from 'react-i18next';
import {
  ITranslationDetailProps,
  PriceRender,
  StyledDialog,
  TranslationDetail,
} from '../../ui';
import { IOfferCureDto } from '../../model/IOfferCureDto';
import { ICureDto } from '../../model';
import { CureApi, ICureApi } from '../../services';

export interface IOfferCureDisplayProps {
  offerCures: IOfferCureDto[];
}

export function OfferCureDisplay(props: IOfferCureDisplayProps) {
  const { t, i18n } = useTranslation('offer-package');
  const api: ICureApi = new CureApi();
  const { offerCures } = props;

  const [cures, setCures] = useState<ICureDto[]>();
  const [modalContent, setModalContent] = useState<ITranslationDetailProps>();

  useEffect(() => {
    const idsToLoad = [...distinctBy(offerCures, (e) => e.cureId)]
      .map((e) => e.cureId)
      .map((e) => from(api.getById(e)));

    combineLatest(idsToLoad).subscribe({
      next: (data) => setCures(data),
    });
  }, [offerCures]);

  if (!cures) {
    return <p>Loading...</p>;
  }

  const renderCure = (id: number) => {
    const cure = cures?.find((e) => e.id === id);
    if (!cure) {
      return '';
    }

    const translation = cure?.cureTranslationList.find(
      (e) => e.langCode === i18n.language
    );

    return translation?.title;
  };

  const renderCureDescription = (id: number) => {
    const cure = cures?.find((e) => e.id === id);
    if (!cure) {
      return '';
    }

    const translation = cure?.cureTranslationList.find(
      (e) => e.langCode === i18n.language
    );

    return translation?.teaser;
  };

  return (
    <div>
      <StyledDialog
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent && (
          <TranslationDetail
            text={modalContent.text}
            imageLinks={modalContent.imageLinks}
          ></TranslationDetail>
        )}
      </StyledDialog>

      <h3 className="text-lg font-bold">{t('cure.title')}</h3>

      <ul className="space-y-2">
        {offerCures.map((item) => (
          <li key={item.id}>
            <button
              type="button"
              className="flex group justify-between items-center w-full gap-2 bg-slate-50 hover:bg-slate-100 hover:shadow rounded p-2 print:p-0"
              onClick={() => {
                const product = cures?.find((e) => e.id === item.cureId);
                const translation = product?.cureTranslationList.find(
                  (e) => e.langCode === i18n.language
                );

                if (translation) {
                  setModalContent({
                    text: translation,
                    imageLinks: product?.imageLinks ?? [],
                  });
                }
              }}
            >
              <div className="text-left">
                <span className="font-bold">
                  {item.amount}x {renderCure(item.cureId)}
                </span>
                <br />
                <span>{renderCureDescription(item.cureId)}</span>
                <p className="group-hover:underline">{t('shared.more')}...</p>
              </div>

              <div className="text-right">
                <PriceRender
                  price={item.price}
                  offerPrice={item.offerPrice}
                ></PriceRender>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OfferCureDisplay;

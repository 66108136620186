import { useTranslation } from 'react-i18next';
import Header from './header';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Offer from './offer';
import Introduction from './introduction';
import Footer from './footer';
import { IOfferDto } from '../model/IOfferDto';
import {
  CureApi,
  ICureApi,
  INotificationService,
  IOfferApi,
  IOfferReservationApi,
  IProductApi,
  NotificationService,
  OfferApi,
  OfferReservationApi,
  ProductApi,
} from '../services';
import Overlay from '../ui/overlay';
import { DetoxPackage, General, Spa, Yoga } from './language-parts';
import { IOfferReservationDto, IReservationResponseDto } from '../model';
import { IGaItemDto, IGaPurchaseEventDto } from '../model/analytics';
import {
  getGaUserIdFromEmail,
  getHashedPhoneNumber,
  gtmEvent,
} from '../utility';
import { MapAllToAnalyticsItem } from '../mapping';
import { IRoomCategoryApi, RoomCategoryApi } from '../services/roomCategoryApi';
import { ITreatmentApi, TreatmentApi } from '../services/treatmentApi';
import PaymentPicker from './payment-picker';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function Booking() {
  const [searchParams] = useSearchParams();
  const offerReservationId = searchParams.get('offer-reservation');
  if (!offerReservationId) {
    throw new Error();
  }

  const { t } = useTranslation();

  const notificationService: INotificationService = new NotificationService();
  const service: IOfferReservationApi = new OfferReservationApi();
  const offerService: IOfferApi = new OfferApi();
  const cureApiService: ICureApi = new CureApi();
  const productApiService: IProductApi = new ProductApi();
  const roomCategoryApiService: IRoomCategoryApi = new RoomCategoryApi();
  const treatmentApiService: ITreatmentApi = new TreatmentApi();

  const [response, setResponse] = useState<IReservationResponseDto>();

  const [offerReservation, setOfferReservation] =
    useState<IOfferReservationDto>();
  const [offer, setOffer] = useState<IOfferDto>();

  useEffect(() => {
    service
      .getById(offerReservationId)
      .then((e) => setOfferReservation(e))
      .finally();
  }, [offerReservationId]);

  useEffect(() => {
    if (offerReservation) {
      offerService
        .getById(offerReservation?.offerId)
        .then((e) => setOffer(e))
        .finally();
    } else {
      setOffer(undefined);
    }
  }, [offerReservation]);

  useEffect(() => {
    if (offerReservation && offer && !offerReservation.tracked) {
      if (!offerReservation.tracked) {
        onTrack(offerReservation);
      }
    }
  }, [offerReservation, offer]);

  const onTrack = async (dto: IOfferReservationDto): Promise<void> => {
    try {
      await delay(3000);

      console.log('tracking');

      //TRACK
      if (offer) {
        const items: IGaItemDto[] = [];

        for (let index = 0; index < offer.offerCureList.length; index++) {
          const element = offer.offerCureList[index];
          const cure = await cureApiService.getById(element.cureId);

          items.push(
            MapAllToAnalyticsItem(
              {
                ...cure,
                id: `cure_${cure.id}`,
              },
              (element.offerPrice ?? element.price) / element.amount,
              element.amount
            )
          );
        }

        for (let index = 0; index < offer.offerProductList.length; index++) {
          const element = offer.offerProductList[index];
          const product = await productApiService.getById(element.productId);

          items.push(
            MapAllToAnalyticsItem(
              {
                ...product,
                id: `product_${product.id}`,
              },
              (element.offerPrice ?? element.price) / element.amount,
              element.amount
            )
          );
        }

        for (
          let index = 0;
          index < offer.offerRoomCategoryList.length;
          index++
        ) {
          const element = offer.offerRoomCategoryList[index];
          const room = await roomCategoryApiService.getById(
            element.roomCategoryId
          );

          items.push(
            MapAllToAnalyticsItem(
              {
                ...room,
                id: `room_${room.id}`,
              },
              element.offerPrice ?? element.price,
              1
            )
          );
        }

        for (let index = 0; index < offer.offerTreatmentList.length; index++) {
          const element = offer.offerTreatmentList[index];
          const treatment = await treatmentApiService.getById(
            element.treatmentId
          );

          items.push(
            MapAllToAnalyticsItem(
              {
                ...treatment,
                id: `treatment_${treatment.id}`,
              },
              element.offerPrice ?? element.price,
              1
            )
          );
        }

        const payload: IGaPurchaseEventDto = {
          currency: 'EUR',
          value: offer.price,
          transaction_id: `offer_reservation_${dto.id ?? 'unkown'}`,
          items: items,
        };

        gtmEvent('purchase', await getGaUserIdFromEmail(dto.contactMail), {
          ...payload,
          phone: getHashedPhoneNumber(dto.contactPhone),
        });

        setOfferReservation({ ...dto, tracked: true });

        await service.track(dto.id);
      }
    } catch {
      console.log('could not track');
    }
  };

  const onDeposit = async () => {
    if (offerReservation) {
      const depositLink = await service.payDeposit(offerReservation.id);

      if (typeof window !== 'undefined' && depositLink.checkoutUrl) {
        window.location.href = depositLink.checkoutUrl;
      }

      //if redirect does not work
      setResponse(depositLink);
    }
  };

  if (!offerReservation || !offer) {
    return <p>Loading...</p>;
  }

  if (response) {
    return (
      <div className="mb-10 space-y-8">
        <Header></Header>

        <PaymentPicker response={response}></PaymentPicker>
      </div>
    );
  }

  return (
    <div className="mb-24">
      <Header></Header>

      <Introduction
        firstname={offerReservation.contactFirstname}
        lastname={offerReservation.contactLastname}
        isOffer={false}
      ></Introduction>

      <div className="container space-y-4 mt-4">
        <div className="container max-w-3xl px-2 sm:px-0">
          <div className="space-y-4">
            <Offer
              onDeposit={
                offerReservation.needsDeposit ? () => onDeposit() : undefined
              }
              isBooked={true}
              overridePrice={offerReservation.acceptedCost}
              overrideDeposit={offerReservation.acceptedDeposit}
              offer={offer}
              key={offer.id}
            ></Offer>

            <DetoxPackage></DetoxPackage>

            <Spa></Spa>

            <Yoga></Yoga>

            <General></General>
          </div>
        </div>

        <Footer></Footer>
      </div>

      <Overlay service={notificationService}></Overlay>
    </div>
  );
}

export default Booking;

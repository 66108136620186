import { ISeasonCurePriceDto } from '../model';
import { IGaItemDto, DefaultGaItemDto } from '../model/analytics';

export function MapCureToAnalyticsItem(
  seasonCure: ISeasonCurePriceDto,
  quantity: number | undefined = undefined,
  item_list_name: string | undefined = undefined
): IGaItemDto {
  return {
    ...DefaultGaItemDto,
    item_id: `cure_${seasonCure.cure.id}`,
    item_name: seasonCure.cure.internalName,
    item_category: seasonCure.cure.gaItemCategory ?? undefined,
    item_category2: seasonCure.cure.gaItemCategory2 ?? undefined,
    item_category3: seasonCure.cure.gaItemCategory3 ?? undefined,
    item_category4: seasonCure.cure.gaItemCategory4 ?? undefined,
    item_category5: seasonCure.cure.gaItemCategory5 ?? undefined,
    item_list_name: item_list_name,
    item_variant: seasonCure.cure.gaItemVariant ?? undefined,
    price: seasonCure.priceAdult,
    quantity: quantity,
  };
}

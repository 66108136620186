import { useTranslation } from 'react-i18next';

export function Spa() {
  const { i18n } = useTranslation('offer-package');

  if (i18n.language === 'de') {
    return (
      <div className="bg-white border shadow-md rounded-md overflow-clip">
        <header className="bg-primary-600 px-4 py-5">
          <h2 className="text-white font-bold">Ayurveda & Thermal Spa</h2>
        </header>
        <div className="grid md:grid-cols-3">
          <div className="p-4">
            <img
              src="https://cdn.mandira-ayurveda.at/images/resort/2020/spa.jpg"
              alt="Spa"
            ></img>
          </div>

          <div className="p-4 md:col-span-2">
            <p>
              Abtauchen in der Welt des steirischen Heilbades, eintauchen in den
              Holistic Ayurveda Kosmos auf 1.760 m² …
            </p>

            <h3 className="font-bold mt-2">Gesundbaden</h3>
            <ul className="list list-disc ms-5">
              <li>Heilthermal-Panoramapool 32 °C</li>
              <li>Außenpool 28 °C</li>
            </ul>

            <h3 className="font-bold mt-2">Entspannen und regenerieren</h3>
            <ul className="list list-disc ms-5">
              <li>Keltische Sauna „Lautrum“ 90 °C</li>
              <li>Steirische Kräutersauna 65 °C</li>
              <li>Mineralien-Dampfbad 45 °C</li>
              <li>Sole-Sauna 75 °C</li>
              <li>Infrarotsauna</li>
              <li>Eisgrotte</li>
              <li>Ruheraum Buddha's Place</li>
              <li>Gemütliche Ruheplätze im gesamten Bade- & Saunabereich</li>
            </ul>

            <p className="mt-2">
              Als Kur-Gast genießen Sie außerdem exklusiven Zutritt zu{' '}
              <strong>Ganesha's</strong> Garden und zum Ruheraum{' '}
              <strong>Ganesha's Spirit</strong>.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border shadow-md rounded-md overflow-clip">
      <header className="bg-primary-600 px-4 py-5">
        <h2 className="text-white font-bold">Ayurveda & thermal spa</h2>
      </header>
      <div className="grid md:grid-cols-3">
        <div className="p-4">
          <img
            src="https://cdn.mandira-ayurveda.at/images/resort/2020/spa.jpg"
            alt="Spa"
          ></img>
        </div>

        <div className="p-4 md:col-span-2">
          <p>
            Immerse yourself in the world of the Styrian spa, immerse yourself
            in the Holistic Ayurveda cosmos on 1.760 m² …
          </p>

          <h3 className="font-bold mt-2">Healthy bathing</h3>
          <ul className="list list-disc ms-5">
            <li>Healing thermal panorama pool 32 °C</li>
            <li>Outdoor pool 28 °C</li>
          </ul>

          <h3 className="font-bold mt-2">Relax and regenerate</h3>
          <ul className="list list-disc ms-5">
            <li>Celtic sauna “Lautrum” 90 °C</li>
            <li>Styrian herbal sauna 65 °C</li>
            <li>Mineral steam bath 45 °C</li>
            <li>Brine sauna 75 °C</li>
            <li>Infrared sauna</li>
            <li>Ice grotto</li>
            <li>Relaxation room Buddha's Place</li>
            <li>
              Comfortable resting places in the entire bathing & sauna area
            </li>
          </ul>

          <p className="mt-2">
            As a spa guest, you also enjoy exclusive access to{' '}
            <strong>Ganesha's</strong> Garden and to the relaxation room{' '}
            <strong>Ganesha's Spirit</strong>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Spa;

import {
  IRoomCategoryApi,
  RoomCategoryApi,
} from '../../services/roomCategoryApi';
import { IOfferRoomCategoryDto } from '../../model/IOfferRoomCategoryDto';
import { useEffect, useState } from 'react';
import { combineLatest, from } from 'rxjs';
import { distinctBy } from '../../utility';
import { IRoomCategoryDto } from '../../model';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  ITranslationDetailProps,
  PriceRender,
  StyledDialog,
  TranslationDetail,
} from '../../ui';

export interface IOfferRoomCategoryDisplayProps {
  rooms: IOfferRoomCategoryDto[];
}

export function OfferRoomCategoryDisplay(
  props: IOfferRoomCategoryDisplayProps
) {
  const { t, i18n } = useTranslation('offer-package');
  const api: IRoomCategoryApi = new RoomCategoryApi();
  const { rooms } = props;

  const [roomCategories, setRoomCategories] = useState<IRoomCategoryDto[]>();
  const [modalContent, setModalContent] = useState<ITranslationDetailProps>();

  useEffect(() => {
    const idsToLoad = [...distinctBy(rooms, (e) => e.roomCategoryId)]
      .map((e) => e.roomCategoryId)
      .map((e) => from(api.getById(e)));

    combineLatest(idsToLoad).subscribe({
      next: (data) => setRoomCategories(data),
    });
  }, [rooms]);

  if (!roomCategories) {
    return <p>Loading...</p>;
  }

  const renderRoomCategory = (id: number) => {
    const roomCategory = roomCategories?.find((e) => e.id === id);
    if (!roomCategory) {
      return '';
    }

    const translation = roomCategory?.roomCategoryTranslationList.find(
      (e) => e.langCode === i18n.language
    );

    return translation?.title;
  };

  return (
    <div>
      <StyledDialog
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent && (
          <TranslationDetail
            text={modalContent.text}
            imageLinks={modalContent.imageLinks}
          ></TranslationDetail>
        )}
      </StyledDialog>

      <h3 className="text-lg font-bold">{t('room-category.title')}</h3>

      <ul className="space-y-2">
        {rooms.map((room) => (
          <li key={room.id}>
            <button
              type="button"
              className="flex group justify-between items-center w-full gap-2 bg-slate-50 hover:bg-slate-100 hover:shadow rounded p-2 print:p-0"
              onClick={() => {
                const roomCategory = roomCategories?.find(
                  (e) => e.id === room.roomCategoryId
                );
                const translation =
                  roomCategory?.roomCategoryTranslationList.find(
                    (e) => e.langCode === i18n.language
                  );

                if (translation) {
                  setModalContent({
                    text: translation,
                    imageLinks: roomCategory?.imageLinks ?? [],
                  });
                }
              }}
            >
              <div className="text-left">
                <span className="font-bold">
                  {renderRoomCategory(room.roomCategoryId)}
                </span>

                <br />

                <span>
                  {`${format(room.from, 'dd.MM.yyyy')} - ${format(
                    room.to,
                    'dd.MM.yyyy'
                  )}`}
                </span>
                <br />

                <span>
                  {`${t('room-category.occupancy')}: ${room.occupancy}${
                    room.dog === true ? ' - ' + t('room-category.dog') : ''
                  }`}
                </span>
                <p className="group-hover:underline">{t('shared.more')}...</p>
              </div>

              <div className="text-right">
                <PriceRender
                  price={room.price}
                  offerPrice={room.offerPrice}
                ></PriceRender>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OfferRoomCategoryDisplay;

import { IOfferPackageDto } from '../model/IOfferPackageDto';
import { axiosMod } from '../utility';

export interface IOfferPackageApi {
  getById(id: string): Promise<IOfferPackageDto>;
}

export class OfferPackageApi implements IOfferPackageApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/offer-package';
  }

  async getById(id: string): Promise<IOfferPackageDto> {
    const response = await axiosMod().get<IOfferPackageDto>(
      `${this.endpointUrl}/${id}`
    );

    return response.data;
  }
}

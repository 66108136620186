import { IRoomCategoryDto } from '../model';
import { axiosMod } from '../utility';

export interface IRoomCategoryApi {
  getById(id: number): Promise<IRoomCategoryDto>;
}

export class RoomCategoryApi implements IRoomCategoryApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/room-category';
  }

  async getById(id: number): Promise<IRoomCategoryDto> {
    const response = await axiosMod().get<IRoomCategoryDto>(
      `${this.endpointUrl}/${id}`
    );

    return response.data;
  }
}

import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export interface IStyledDialogProps {
  /**
   * defaults to true when undefined
   */
  isOpen?: boolean;

  /**
   * on closing when clicking outside the dialog
   */
  onClose?: () => unknown;

  children: React.ReactNode;
}

export function StyledDialog(props: IStyledDialogProps) {
  return (
    <Dialog
      as="div"
      open={props.isOpen ?? true}
      className="fixed top-0 left-0 w-full h-dvh z-10"
      onClose={() => props.onClose && props.onClose()}
    >
      <div
        className={classNames(
          'fixed inset-0 bg-gray-500 bg-opacity-40 transition-all z-10',
          props.isOpen && 'backdrop-blur-sm'
        )}
      />

      <div className="relative h-dvh w-full z-20 p-10 max-w-3xl mx-auto">
        <Dialog.Panel
          as="div"
          className="relative flex flex-col bg-white shadow-xl h-full w-full"
        >
          <button
            type="button"
            onClick={() => props.onClose && props.onClose()}
            className="absolute z-30 top-0 right-0 flex justify-end bg-white hover:text-red-700 p-2 "
          >
            <XMarkIcon className="w-6 h-6"></XMarkIcon>
          </button>

          <div className="overflow-y-scroll pb-16 z-20">{props.children}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default StyledDialog;

import { FormState } from 'react-hook-form';
import { IEnquiryDto } from '../model';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '../ui';
import classNames from 'classnames';
import { useEffect } from 'react';
import { IEnquiryState } from './state';

export interface ISubmitFormProps {
  enquiryState: IEnquiryState;

  formState: FormState<IEnquiryDto>;
}

export function SubmitForm(props: ISubmitFormProps) {
  const { t } = useTranslation();
  const {
    formState: { isSubmitting, isLoading, isValid },
  } = props;

  useEffect(() => {
    props.enquiryState.isFormValid(isValid);
  }, [isValid]);

  return (
    <div className="fixed w-full flex justify-center left-0 bottom-0 p-3 bg-white">
      <StyledButton
        className={classNames(
          'w-64 h-12 place-items-center',
          isValid &&
            'outline-2 outline outline-primary-600 outline-offset-2 animate-pulse-outline'
        )}
        design="primary"
        type="submit"
        disabled={isSubmitting || isLoading || !isValid}
      >
        <span className="w-full">{t('action')}</span>
      </StyledButton>
    </div>
  );
}

export default SubmitForm;

import { useTranslation } from 'react-i18next';
import Header from './header';

export function PaymentError() {
  const { t } = useTranslation('offer-package');

  return (
    <div>
      <Header></Header>

      <div className="container px-2">
        <div className="flex justify-center">
          <div className="mt-4 space-y-2 max-w-lg w-full">
            <h1 className="text-lg font-bold text-slate-800">
              {t('finish.api-error')}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentError;

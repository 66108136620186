import { AtSymbolIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { StyledButton, StyledLink } from '../ui';
import { useTranslation } from 'react-i18next';
import Header from './header';
import { IOfferPackageApi, OfferPackageApi } from '../services/offerPackageApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IOfferPackageDto } from '../model/IOfferPackageDto';
import { throwError } from 'rxjs';
import { IOfferDto } from '../model/IOfferDto';
import {
  OfferCureDisplay,
  OfferRoomCategoryDisplay,
  OfferProductDisplay,
  OfferBoardDisplay,
  OfferTreatmentDisplay,
  OfferPromotionDisplay,
} from './offer-parts';
import { DateOnly } from '../utility';
import { format } from 'date-fns';

export interface IOfferProps {
  offer: IOfferDto;
  validUntil?: DateOnly;
  index?: number;

  overridePrice?: number;
  overrideDeposit?: number;

  isBooked?: boolean;

  onSelect?: () => void;
  onDeposit?: () => void;
}

export function Offer(props: IOfferProps) {
  const { offer, validUntil } = props;
  const { t, i18n } = useTranslation('offer-package');

  const message = (offer.offerMessageList ?? []).find(
    (e) => e.langCode === i18n.language
  );

  return (
    <div className="border rounded-md bg-white overflow-clip shadow-md *:p-4">
      <div className="bg-slate-50 flex items-center justify-between">
        {props.isBooked ? (
          <h2 className="text-xl">{t('shared.booking')}</h2>
        ) : (
          <h2 className="text-xl">
            {t('shared.offer')}
            {props.index !== undefined && ` #${props.index + 1}`}
          </h2>
        )}

        {validUntil && (
          <span className="text-sm">
            {t('shared.valid-until')}: {format(validUntil, 'dd.MM.yyy')}
          </span>
        )}
      </div>

      {message && <p className="whitespace-pre-line">{message.message}</p>}

      <div className="space-y-4">
        {offer.offerRoomCategoryList &&
          offer.offerRoomCategoryList.length > 0 && (
            <OfferRoomCategoryDisplay
              rooms={offer.offerRoomCategoryList}
            ></OfferRoomCategoryDisplay>
          )}

        {offer.offerCureList && offer.offerCureList.length > 0 && (
          <>
            <hr />
            <OfferCureDisplay
              offerCures={offer.offerCureList}
            ></OfferCureDisplay>
          </>
        )}

        {offer.offerProductList && offer.offerProductList.length > 0 && (
          <>
            <hr />
            <OfferProductDisplay
              offerProducts={offer.offerProductList}
            ></OfferProductDisplay>
          </>
        )}

        {offer.offerTreatmentList && offer.offerTreatmentList.length > 0 && (
          <>
            <hr />
            <OfferTreatmentDisplay
              offerTreatments={offer.offerTreatmentList}
            ></OfferTreatmentDisplay>
          </>
        )}

        {offer.offerBoardList && offer.offerBoardList.length > 0 && (
          <>
            <hr />
            <OfferBoardDisplay
              offerBoards={offer.offerBoardList}
            ></OfferBoardDisplay>
          </>
        )}

        <hr />

        {offer.offerPromotion && (
          <OfferPromotionDisplay
            promotion={offer.offerPromotion}
          ></OfferPromotionDisplay>
        )}

        <div className="flex justify-between items-center gap-2 w-full">
          <span className="font-bold">{t('shared.title')}:</span>

          <span className="font-bold">
            {(props.overridePrice ?? offer.price).toFixed(2).replace('.', ',')}€
          </span>
        </div>

        <div className="flex justify-between items-center gap-2 w-full">
          <span className="font-bold">{t('shared.deposit')}:</span>

          <span className="font-bold">
            {(props.overrideDeposit ?? Math.ceil(offer.price / 3.0 / 50) * 50)
              .toFixed(2)
              .replace('.', ',')}
            €
          </span>
        </div>
      </div>

      {props.onSelect && (
        <div className="bg-slate-100 flex flex-row-reverse justify-between items-center print:hidden">
          <StyledButton
            type="button"
            design="primary"
            className="outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline"
            onClick={() => props.onSelect && props.onSelect()}
          >
            {t('shared.book')}
          </StyledButton>
        </div>
      )}

      {props.onDeposit && (
        <div className="bg-slate-100 flex flex-row-reverse justify-between items-center print:hidden">
          <StyledButton
            type="button"
            design="primary"
            className="outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline"
            onClick={() => props.onDeposit && props.onDeposit()}
          >
            {t('finish.pay-deposit')}
          </StyledButton>
        </div>
      )}
    </div>
  );
}

export default Offer;

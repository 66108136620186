import {
  IVoucherDto,
  IVoucherResponseDto,
  IVoucherTemplateDto,
} from '../model';
import { axiosMod } from '../utility';

export interface IVoucherApi {
  addVoucher(dto: IVoucherDto): Promise<IVoucherResponseDto>;

  renderDraftVoucher(dto: IVoucherTemplateDto): Promise<Blob>;
}

export class VoucherApi implements IVoucherApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/voucher';
  }

  async addVoucher(dto: IVoucherDto): Promise<IVoucherResponseDto> {
    const response = await axiosMod().post<IVoucherResponseDto>(
      this.endpointUrl + '/guest',
      dto
    );

    return response.data;
  }

  async renderDraftVoucher(dto: IVoucherTemplateDto): Promise<Blob> {
    const response = await axiosMod().post<Blob>(
      this.endpointUrl + '/draft',
      dto,
      { responseType: 'blob' }
    );

    return response.data;
  }
}

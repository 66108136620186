import { useTranslation } from 'react-i18next';
import Form from './form';
import Header from './header';
import { INotificationService, NotificationService } from '../services';
import Overlay from '../ui/overlay';
import {
  axiosMod,
  getGaClientId,
  getGaSessionId,
  getGaUserIdFromEmail,
  gtmEvent,
} from '../utility';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { IEnquiryOptionDto } from '../model';
import { EnquiryState, IEnquiryState } from './state';

const notificationService: INotificationService = new NotificationService();
const enquiryState: IEnquiryState = new EnquiryState();

export function EnquiryPage() {
  const { t, i18n } = useTranslation();
  const q = useSearchParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const foundTreatment = searchParams.get('treatment');
  const foundRoom = searchParams.get('room');

  let roomOptions: IEnquiryOptionDto[];

  let treatmentOptions: IEnquiryOptionDto[];

  if (foundTreatment) {
    treatmentOptions = [
      {
        name: foundTreatment,
        valueEnabled: true,
      },
    ];
  } else {
    treatmentOptions = [
      {
        name: t('treatment.snuff'), //Ayuveda Tasting
        valueEnabled: false,
      },
      {
        name: t('treatment.ayurveda'), //Pancha Karma
        valueEnabled: false,
      },
      {
        name: t('treatment.live'), //Detox
        valueEnabled: false,
      },
      {
        name: t('treatment.stress'), //Digital Detox - Burnout
        valueEnabled: false,
      },
      // {
      //   name: t('treatment.yoga'),
      //   valueEnabled: false,
      // },
    ];
  }

  if (foundRoom) {
    roomOptions = [
      {
        name: foundRoom,
        valueEnabled: true,
      },
    ];
  } else {
    roomOptions = [
      {
        name: t('room.big-suite'),
        valueEnabled: false,
      },
      {
        name: t('room.suite'),
        valueEnabled: false,
      },
      {
        name: t('room.normal'),
        valueEnabled: false,
      },
      {
        name: t('room.dog'),
        valueEnabled: false,
      },
    ];
  }

  //track on page mount/load
  useEffect(() => {
    gtmEvent('enquiry_start');
  }, []);

  return (
    <div>
      <Overlay service={notificationService}></Overlay>

      <Header></Header>
      <div className="container px-2">
        <Form
          enquiryState={enquiryState}
          roomOptions={roomOptions}
          treatmentOptions={treatmentOptions}
          onSubmit={async (data) => {
            const userId = await getGaUserIdFromEmail(data.contactMail);
            const clientId = getGaClientId();
            const sessionId = getGaSessionId();

            if (q[0].get('test') === 'yes') {
              console.log('found test');
              console.log('client_id', clientId);
              console.log('user_id', userId);
              console.log('session_id', sessionId);
              navigate('finish');

              return;
            }

            await axiosMod()
              .post('https://mandira.mauracher.cc/api/enquiry', {
                ...data,
                userId: userId,
                clientId: clientId,
                sessionId: sessionId,
                contactLanguage: i18n.language.toLowerCase(),
              })
              .then(() => {
                gtmEvent('enquiry_finished', userId);

                notificationService.createNotification({
                  type: 'success',
                  title: 'Vielen Dank für die Anfrage!',
                  description: 'Wir melden uns in kürze!',
                });
              })
              .finally(() => {
                navigate('finish');
              });
          }}
        ></Form>
      </div>
    </div>
  );
}

export default EnquiryPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  Axios,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  InternalAxiosRequestConfig,
} from 'axios';
import { format, parse, parseISO } from 'date-fns';
import { DateOnly } from './date-only';

export function axiosMod(): Axios {
  const client = axios.create({
    transformRequest: [
      handleDatesRequest,
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
  });

  client.interceptors.response.use((originalResponse) => {
    handleDatesResponse(originalResponse.data);
    return originalResponse;
  });

  return client;
}

function handleDatesRequest(
  this: InternalAxiosRequestConfig,
  data: any,
  headers: AxiosRequestHeaders
): any {
  if (data && typeof data === 'object') {
    handleDatesData(data);
  }

  if (this.params) {
    handleDatesData(this.params);
  }

  if (headers) {
    handleDatesData(headers);
  }

  return data;
}

function handleDatesData(data: any) {
  for (const key of Object.keys(data)) {
    const value = data[key];

    if (value && value instanceof DateOnly) {
      data[key] = format(value, 'yyyy-MM-dd');
    } else if (value && typeof value === 'object') {
      handleDatesData(value);
    }
  }
}

const isoDateTimeFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isoDateFormat = /^\d{4}-\d{2}-\d{2}$/;

function isIsoDateTimeString(value: any): boolean {
  return value && typeof value === 'string' && isoDateTimeFormat.test(value);
}

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDatesResponse(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateTimeString(value)) {
      body[key] = parseISO(value);
    } else if (isIsoDateString(value)) {
      body[key] = new DateOnly(parse(value, 'yyyy-MM-dd', new Date()));
    } else if (typeof value === 'object') {
      handleDatesResponse(value);
    }
  }
}

import { useTranslation } from 'react-i18next';
import Form from './form';
import Header from './header';
import { INotificationService, NotificationService } from '../services';
import Overlay from '../ui/overlay';
import { axiosMod, getGaUserIdFromEmail, gtmEvent } from '../utility';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { IEnquiryOptionDto } from '../model';
import { EnquiryState, IEnquiryState } from './state';

const notificationService: INotificationService = new NotificationService();
const enquiryState: IEnquiryState = new EnquiryState();

export function ConsultationPage() {
  const { t, i18n } = useTranslation();
  const q = useSearchParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const foundTreatment = searchParams.get('treatment');

  let treatmentOptions: IEnquiryOptionDto[];

  if (foundTreatment) {
    treatmentOptions = [
      {
        name: foundTreatment,
        valueEnabled: true,
      },
    ];
  } else {
    treatmentOptions = [
      {
        name: t('consultation.treatment.backpain'),
        valueEnabled: false,
      },
      {
        name: t('consultation.treatment.weight'),
        valueEnabled: false,
      },
      {
        name: t('consultation.treatment.burnout'),
        valueEnabled: false,
      },
      {
        name: t('consultation.treatment.hormones'),
        valueEnabled: false,
      },
      {
        name: t('consultation.treatment.detox'),
        valueEnabled: false,
      },
    ];
  }

  //track on page mount/load
  useEffect(() => {
    gtmEvent('enquiry_start');
  }, []);

  return (
    <div>
      <Overlay service={notificationService}></Overlay>

      <Header></Header>
      <div className="container px-2">
        <Form
          enquiryState={enquiryState}
          treatmentOptions={treatmentOptions}
          onSubmit={async (data) => {
            const userId = await getGaUserIdFromEmail(data.contactMail);

            await axiosMod()
              .post('https://mandira.mauracher.cc/api/enquiry', {
                ...data,
                userId: userId,
                contactLanguage: i18n.language.toLowerCase(),
              })
              .then(() => {
                gtmEvent('enquiry_finished', userId);

                notificationService.createNotification({
                  type: 'success',
                  title: 'Vielen Dank für die Anfrage!',
                  description: 'Wir melden uns in kürze!',
                });
              })
              .finally(() => {
                navigate(`/${i18n.language}/finish`);
              });
          }}
        ></Form>
      </div>
    </div>
  );
}

import { IProductDto } from '../model/IProductDto';
import { axiosMod } from '../utility';

export interface IProductApi {
  getById(id: number): Promise<IProductDto>;
}

export class ProductApi implements IProductApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/product';
  }

  async getById(id: number): Promise<IProductDto> {
    const response = await axiosMod().get<IProductDto>(
      `${this.endpointUrl}/${id}`
    );

    return response.data;
  }
}

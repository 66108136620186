import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useContext, useEffect, useState } from 'react';
import { DateOnly } from '../../utility';
import { CheckApi, INotificationService } from '../../services';
import { ICheckAvailabilityResponseDto } from '../../model';
import {
  startOfMonth,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  addDays,
  format,
  isBefore,
  isAfter,
  isEqual,
  setDate,
} from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { IStepperContext, StepperContext, StyledButton } from '../../ui';
import { useSearchParams } from 'react-router-dom';
import { parseIntParam } from '../../utility/param-helper';

export interface IRangeDatePickerProps {
  notificationService: INotificationService;
}

export function RangeDatePicker(props: IRangeDatePickerProps) {
  const { t, i18n } = useTranslation();
  const methods = useFormContext();
  const stepperContext = useContext<IStepperContext>(StepperContext);

  const [searchParams] = useSearchParams();
  const parsedCure = parseIntParam(searchParams.get('cure'));

  const fromWatch: DateOnly = methods.watch('from');
  const toWatch: DateOnly = methods.watch('to');

  const service = new CheckApi();

  const [selectorDate, setSelectorDate] = useState<DateOnly>(
    methods.getValues('from') ?? addDays(new DateOnly(), 3)
  );

  const [availabilityList, setAvailabilityList] =
    useState<ICheckAvailabilityResponseDto>();

  const isAvailiable = (date: DateOnly): boolean => {
    return (
      availabilityList?.possibleDates.findIndex((f) => isEqual(date, f)) !== -1
    );
  };

  const [dateList, setDateList] = useState<
    {
      date: DateOnly;
      isCurrentMonth: boolean;
      isNextMonth: boolean;
    }[]
  >();
  const [nextDateList, setNextDateList] = useState<
    {
      date: DateOnly;
      isCurrentMonth: boolean;
      isNextMonth: boolean;
    }[]
  >();

  const roomCountWatch = methods.watch('roomCount');

  const selectDate = (date: DateOnly) => {
    if (!fromWatch || toWatch) {
      methods.setValue('from', date);
      methods.setValue('to', null);
      methods.setValue('cureList', []);
      methods.setValue('roomList', []);
      return;
    }

    if (!toWatch) {
      methods.setValue('to', date);
      return;
    }
  };

  useEffect(() => {
    let fixedFrom: DateOnly | undefined = fromWatch ?? undefined;

    if (toWatch) {
      fixedFrom = undefined;
    }

    service
      .getAvailability(
        selectorDate.getMonth() + 1,
        selectorDate.getFullYear(),
        roomCountWatch,
        parsedCure,
        undefined,
        fixedFrom
      )
      .then((e) => setAvailabilityList(e));

    const firstDate = startOfWeek(startOfMonth(selectorDate), {
      weekStartsOn: 1,
    });
    const lastDate = endOfWeek(endOfMonth(selectorDate), { weekStartsOn: 1 });
    const firstNextDate = startOfWeek(
      startOfMonth(addMonths(setDate(selectorDate, 1), 1)),
      {
        weekStartsOn: 1,
      }
    );
    const lastNextDate = endOfWeek(
      endOfMonth(addMonths(setDate(selectorDate, 1), 1)),
      { weekStartsOn: 1 }
    );

    setDateList(undefined);
    setNextDateList(undefined);
    setDateList(
      eachDayOfInterval({
        start: firstDate,
        end: lastDate,
      }).map((currentDate) => {
        return {
          date: currentDate,
          isCurrentMonth: currentDate.getMonth() === selectorDate.getMonth(),
          isNextMonth: currentDate.getMonth() === selectorDate.getMonth() + 1,
        };
      })
    );
    setNextDateList(
      eachDayOfInterval({
        start: firstNextDate,
        end: lastNextDate,
      }).map((currentDate) => {
        return {
          date: currentDate,
          isCurrentMonth: currentDate.getMonth() === selectorDate.getMonth(),
          isNextMonth: currentDate.getMonth() === selectorDate.getMonth() + 1,
        };
      })
    );
  }, [selectorDate, roomCountWatch, fromWatch, toWatch]);

  if (dateList === undefined || nextDateList === undefined) {
    return <>Loading...</>;
  }

  return (
    <div className="flex justify-center">
      <div className="space-y-2 max-w-lg w-full">
        <h2 className="text-[#00604F] font-bold text-lg">
          {t('reservation.range.title')}
        </h2>
        <p className="text-gray-600">{t('reservation.range.description')}</p>

        <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
          <div className="flex items-center text-gray-900 bg-gray-200 rounded-md">
            <button
              type="button"
              className="bg-primary-400 rounded-l-md flex flex-none items-center justify-center p-1.5 text-black hover:bg-primary-700 hover:text-white"
              onClick={() => setSelectorDate(addMonths(selectorDate, -1))}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {format(selectorDate, 'LLLL yyyy', {
                locale: i18n.language === 'en' ? enUS : de,
              })}
            </div>
            <button
              type="button"
              className="bg-primary-400 rounded-r-md flex flex-none items-center justify-center p-1.5 text-black hover:bg-primary-700 hover:text-white"
              onClick={() => setSelectorDate(addMonths(selectorDate, 1))}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {dateList.map((day, dayIdx) =>
              !day.isCurrentMonth ? (
                <div
                  key={`${day.date.getDate()}_${day.date.getMonth()}_${day.date.getFullYear()}`}
                  className="bg-gray-100"
                ></div>
              ) : (
                <button
                  key={`${day.date.getDate()}_${day.date.getMonth()}_${day.date.getFullYear()}`}
                  type="button"
                  disabled={!isAvailiable(day.date)}
                  className={classNames(
                    'focus:z-10 flex justify-center',
                    dayIdx === 0 && 'rounded-tl-lg',
                    dayIdx === 6 && 'rounded-tr-lg',
                    dayIdx === dateList.length - 7 && 'rounded-bl-lg',
                    dayIdx === dateList.length - 1 && 'rounded-br-lg',
                    isAvailiable(day.date) && 'bg-white hover:bg-gray-100',
                    !isAvailiable(day.date) &&
                      day.isCurrentMonth &&
                      'bg-gray-200',
                    !isAvailiable(day.date) &&
                      !day.isCurrentMonth &&
                      'bg-gray-200'
                  )}
                  onClick={() => {
                    if (new DateOnly() > day.date) {
                      props.notificationService.createNotification({
                        type: 'normal',
                        title: t('reservation.error.past-date'),
                        hideAfterSeconds: 10,
                      });
                      return;
                    }

                    if (!isAvailiable(day.date)) {
                      props.notificationService.createNotification({
                        type: 'normal',
                        title: t('reservation.error.missing-date'),
                        hideAfterSeconds: 10,
                      });
                      return;
                    }

                    selectDate(day.date);
                  }}
                >
                  <div
                    className={classNames(
                      'w-full h-8 my-2 flex items-center justify-center',
                      isEqual(day.date, fromWatch) &&
                        'bg-primary-600 ml-3 pr-3 text-white rounded-l-full',
                      isAfter(day.date, fromWatch) &&
                        isBefore(day.date, toWatch) &&
                        'bg-primary-600 text-white',
                      isEqual(day.date, toWatch) &&
                        'bg-primary-600 mr-3 pl-3 text-white rounded-r-full'
                    )}
                  >
                    {day.date.getDate()}
                  </div>
                </button>
              )
            )}
          </div>
        </div>

        <div className="pt-4 mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
          <div className="flex items-center text-gray-900 bg-gray-200 py-1.5 rounded-md">
            <div className="flex-auto text-sm font-semibold">
              {format(addMonths(setDate(selectorDate, 1), 1), 'LLLL yyyy', {
                locale: i18n.language === 'en' ? enUS : de,
              })}
            </div>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {nextDateList.map((day, dayIdx) =>
              !day.isNextMonth ? (
                <div
                  key={`${day.date.getDate()}_${day.date.getMonth()}_${day.date.getFullYear()}`}
                  className="bg-gray-100"
                ></div>
              ) : (
                <button
                  key={`${day.date.getDate()}_${day.date.getMonth()}_${day.date.getFullYear()}`}
                  type="button"
                  disabled={!isAvailiable(day.date)}
                  className={classNames(
                    'focus:z-10 flex justify-center',
                    dayIdx === 0 && 'rounded-tl-lg',
                    dayIdx === 6 && 'rounded-tr-lg',
                    dayIdx === dateList.length - 7 && 'rounded-bl-lg',
                    dayIdx === dateList.length - 1 && 'rounded-br-lg',
                    isAvailiable(day.date) && 'bg-white hover:bg-gray-100',
                    !isAvailiable(day.date) &&
                      day.isCurrentMonth &&
                      'bg-gray-200',
                    !isAvailiable(day.date) &&
                      !day.isCurrentMonth &&
                      'bg-gray-200'
                  )}
                  onClick={() => {
                    if (new DateOnly() > day.date) {
                      props.notificationService.createNotification({
                        type: 'normal',
                        title: t('reservation.error.past-date'),
                        hideAfterSeconds: 10,
                      });
                      return;
                    }

                    if (!isAvailiable(day.date)) {
                      props.notificationService.createNotification({
                        type: 'normal',
                        title: t('reservation.error.missing-date'),
                        hideAfterSeconds: 10,
                      });
                      return;
                    }

                    selectDate(day.date);
                  }}
                >
                  <div
                    className={classNames(
                      'w-full h-8 my-2 flex items-center justify-center',
                      isEqual(day.date, fromWatch) &&
                        'bg-primary-600 ml-3 pr-3 text-white rounded-l-full',
                      isAfter(day.date, fromWatch) &&
                        isBefore(day.date, toWatch) &&
                        'bg-primary-600 text-white',
                      isEqual(day.date, toWatch) &&
                        'bg-primary-600 mr-3 pl-3 text-white rounded-r-full'
                    )}
                  >
                    {day.date.getDate()}
                  </div>
                </button>
              )
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 md:flex items-center justify-center gap-2 bottom-0 right-0 p-4 fixed bg-white w-full">
          <StyledButton
            type="button"
            className="w-full md:w-48 h-12 md:h-auto"
            onClick={() =>
              stepperContext.onLastStep && stepperContext.onLastStep()
            }
          >
            <span className="flex justify-center items-center w-full h-full">
              {t('reservation.back')}
            </span>
          </StyledButton>

          <StyledButton
            type="button"
            design="primary"
            rootClassName="w-full col-span-2 md:w-48 h-12 md:h-auto"
            className={classNames(
              'w-full',
              fromWatch &&
                toWatch &&
                'outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline'
            )}
            disabled={!fromWatch || !toWatch}
            onClick={() =>
              stepperContext.onNextStep && stepperContext.onNextStep()
            }
          >
            <span className="flex justify-center items-center w-full h-full">
              {t('reservation.forward')}
            </span>
          </StyledButton>
        </div>
      </div>
    </div>
  );
}

export default RangeDatePicker;

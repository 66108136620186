import Form from './form';
import Header from './header';
import { INotificationService, NotificationService } from '../services';
import Overlay from '../ui/overlay';
import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';
import { gtmEvent } from '../utility';

const notificationService: INotificationService = new NotificationService();

function getRandomInt(numbers: number) {
  const result = Math.floor(Math.random() * numbers);

  if (result < 0) {
    return 0;
  }

  if (result > numbers - 1) {
    return numbers - 1;
  }

  return result;
}

export function Reservation() {
  const [value, setValue] = useLocalStorage<number | null>(
    'reservation.a-b',
    null
  );

  useEffect(() => {
    if (value === null) {
      setValue(getRandomInt(2));
    } else {
      gtmEvent('version.a-b', undefined, {
        'version-a-b': value,
      });
    }
  }, [value]);

  return (
    <div>
      <Overlay service={notificationService}></Overlay>

      <Header></Header>

      <div className="container px-2">
        <Form notificationService={notificationService}></Form>
      </div>
    </div>
  );
}

export default Reservation;

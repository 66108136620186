import { gtmEvent } from '../utility';

export interface IEnquiryState {
  isFormValid(value: boolean): void;
}

export class EnquiryState implements IEnquiryState {
  private _wasValidOnce = false;

  isFormValid(value: boolean): void {
    if (!this._wasValidOnce && value) {
      try {
        gtmEvent('enquiry_input_required_finished');
      } catch {
        console.warn('could not correctly track valid state');
      } finally {
        this._wasValidOnce = true;
      }
    }
  }
}

import { IPromotionDto } from '../model/IPromotionDto';
import { axiosMod } from '../utility';

export interface IPromotionApi {
  find(code: string): Promise<IPromotionDto | null>;

  findVoucher(code: string): Promise<IPromotionDto | null>;
}

export class PromotionApi implements IPromotionApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/promotion';
  }

  async find(code: string): Promise<IPromotionDto | null> {
    const response = await axiosMod().get<IPromotionDto>(
      this.endpointUrl + '/find',
      {
        params: {
          code,
        },
        validateStatus: (status) => status === 404 || status === 200,
      }
    );

    if (response.status === 404) {
      return null;
    }

    return response.data;
  }

  async findVoucher(code: string): Promise<IPromotionDto | null> {
    const response = await axiosMod().get<IPromotionDto>(
      this.endpointUrl + '/find/voucher',
      {
        params: {
          code,
        },
        validateStatus: (status) => status === 404 || status === 200,
      }
    );

    if (response.status === 404) {
      return null;
    }

    return response.data;
  }
}

import { useTranslation } from 'react-i18next';
import { IOfferPromotionDto } from '../../model/IOfferPromotionDto';
import { PriceRender } from '../../ui';

export interface IOfferPromotionDisplayProps {
  promotion: IOfferPromotionDto | null;
}

export function OfferPromotionDisplay(props: IOfferPromotionDisplayProps) {
  const { t } = useTranslation('offer-package');

  const { promotion } = props;

  if (!promotion || !promotion.price) {
    return null;
  }

  return (
    <div className="flex items-center justify-between w-full gap-2">
      <span>{t('promotion.title')}:</span>

      <div className="text-left">
        {'-'}
        <PriceRender price={promotion.price}></PriceRender>
      </div>
    </div>
  );
}

export default OfferPromotionDisplay;

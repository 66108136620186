import { ICureDto } from '../model';
import { BaseCrudApiCaller } from './BaseCrudApiCaller';
import { ICrudApiCaller } from './ICrudApiCaller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICureApi extends ICrudApiCaller<ICureDto, number> {}

export class CureApi
  extends BaseCrudApiCaller<ICureDto, number>
  implements ICureApi
{
  constructor() {
    super('https://mandira.mauracher.cc/api/cure');
  }
}

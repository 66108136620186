import { IReservationDto, IReservationResponseDto } from '../model';
import { axiosMod } from '../utility';

export interface IReservationApi {
  addReservation(dto: IReservationDto): Promise<IReservationResponseDto>;
}

export class ReservationApi implements IReservationApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/reservation';
  }

  async addReservation(dto: IReservationDto): Promise<IReservationResponseDto> {
    const response = await axiosMod().post<IReservationResponseDto>(
      this.endpointUrl + '/guest',
      dto
    );

    return response.data;
  }
}

import { StyledLink } from './app/ui';

/* eslint-disable-next-line */
export interface IErrorFallbackProps {}

export function ErrorFallback(props: IErrorFallbackProps) {
  return (
    <div className="min-h-full py-16 px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-primary-600 sm:text-5xl">
            4xx
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6 space-y-2">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Wir hatten ein Problem
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Das tut uns leid! Bitte kontaktiere unser Team per Mail oder
                Telefon - wir helfen Ihnen gerne weiter!
              </p>

              <div className="flex gap-2">
                <StyledLink href="mailto:info@mandira-ayurveda.at">
                  E-Mail: info@mandira-ayurveda.at
                </StyledLink>
                <StyledLink href="tel:+4333332801">
                  Tel: +43 3333 2801
                </StyledLink>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ErrorFallback;

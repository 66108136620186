import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { hjEvent } from './app/utility';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISiteChangeTrackerProps {}

export function SiteChangeTracker(props: ISiteChangeTrackerProps) {
  const location = useLocation();

  useEffect(() => {
    hjEvent(location.pathname);
  }, [location]);

  return <Outlet></Outlet>;
}

import { AtSymbolIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { StyledLink } from '../ui';
import { useTranslation } from 'react-i18next';

export function Header() {
  const { t } = useTranslation('offer-package');

  return (
    <div>
      <div className="print:flex items-center justify-center my-6 hidden">
        <img
          src="https://cdn.mandira-ayurveda.at/images/logo/2024/dark_cyan_text_black.png"
          alt="Logo"
          width="130px"
          height="100px"
        ></img>
      </div>

      <header className="bg-white py-4 print:hidden">
        <div className="container px-2 flex justify-between items-center">
          <a href="https://www.mandira-ayurveda.at">
            <img
              className="h-16"
              alt="Logo Mandira"
              src="https://cdn.mandira-ayurveda.at/images/logo/2024/dark_cyan_text_black.png"
            ></img>
            <span className="sr-only">{t('header.to-homepage')}</span>
          </a>

          <div id="header-actions" className="flex gap-2">
            <StyledLink
              href="tel:+4333332801"
              classNames="flex items-center gap-1"
            >
              <PhoneIcon className="w-4 h-4"></PhoneIcon> {t('header.call')}
            </StyledLink>

            <StyledLink
              href="mailto:info@mandira-ayurveda.at"
              classNames="hidden md:flex items-center gap-1"
            >
              <AtSymbolIcon className="w-4 h-4"></AtSymbolIcon>{' '}
              {t('header.mail')}
            </StyledLink>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;

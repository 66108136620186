import { CheckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { createContext, useEffect, useState } from 'react';
import { gtmEvent, hjEvent } from '../utility';
import { useLocation } from 'react-router-dom';

export interface IStyledStepperProps {
  onNextStep?: (callbackFn: () => void) => void;
  onLastStep?: (callbackFn: () => void) => void;

  blockNextClick?: boolean;

  children: React.ReactNode[];
}

export interface IStepperContext {
  onNextStep?: () => void;
  onLastStep?: () => void;
}

export const StepperContext = createContext<IStepperContext>({});

export function StyledStepper(props: IStyledStepperProps) {
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [maxStepReported, setMaxStepReported] = useState<number>(-1);
  const maxSteps = props.children.length;

  useEffect(() => {
    window.history.pushState({ step: 0 }, `Step ${0}`);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleBackButton = (event: any) => {
      if (currentStep - 1 >= 0) {
        event.preventDefault();

        // Calculate new step value
        const newStep = Math.max(currentStep - 1, 0);

        // Update the stepper and the URL without adding a new history entry
        setCurrentStep(newStep);
      }
    };

    const handleForward = (event: any) => {
      event.preventDefault();
    };

    // Listen to popstate event
    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('pushstate', handleForward);

    // Cleanup listener
    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('pushstate', handleForward);
    };
  }, [currentStep]);

  const contextInit: IStepperContext = {
    onNextStep: () => {
      if (currentStep < maxSteps - 1) {
        setCurrentStep(currentStep + 1);
        window.history.pushState(
          { step: currentStep + 1 },
          `Step ${currentStep + 1}`
        );
      }
    },
    onLastStep: () => {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    },
  };

  useEffect(() => {
    const stepFinished = currentStep - 1;

    if (stepFinished >= 0 && maxStepReported < stepFinished) {
      gtmEvent('gtm_reservation_step_finished', undefined, {
        step: stepFinished,
      });
      setMaxStepReported(stepFinished);
    }

    if (currentStep !== undefined) {
      hjEvent(`${location.pathname}/#step=${currentStep}`);
    }
  }, [currentStep]);

  return (
    <StepperContext.Provider value={contextInit}>
      <div className="space-y-4">
        <nav aria-label="Progress" className="w-full">
          <ol className="flex justify-center items-center">
            {props.children.map((_, stepIdx) => (
              <li
                key={stepIdx}
                className={classNames(
                  stepIdx !== maxSteps - 1 ? 'pr-8 sm:pr-20' : '',
                  'relative'
                )}
              >
                {currentStep > stepIdx ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-primary-400" />
                    </div>

                    <button
                      type="button"
                      onClick={() => setCurrentStep(stepIdx)}
                      className="relative flex h-8 w-8 items-center justify-center rounded-full bg-primary-400 hover:bg-primary-700"
                    >
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Step: {stepIdx}</span>
                    </button>
                  </>
                ) : currentStep === stepIdx ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <button
                      type="button"
                      onClick={() => setCurrentStep(stepIdx)}
                      className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white"
                      aria-current="step"
                    >
                      <span
                        className="h-2.5 w-2.5 rounded-full bg-primary-400"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Step: {stepIdx}</span>
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <button
                      type="button"
                      disabled={props.blockNextClick === true}
                      onClick={() => setCurrentStep(stepIdx)}
                      className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                    >
                      <span
                        className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Step: {stepIdx}</span>
                    </button>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>

        <hr />

        {props.children[currentStep]}
      </div>
    </StepperContext.Provider>
  );
}

export default StyledStepper;

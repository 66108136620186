import { format } from 'date-fns';
import { DateOnly } from '../utility';
import PromotionInput from './promotion-input';

export interface IPriceSummaryProps {
  fromTitle: string;
  from: DateOnly;

  toTitle: string;
  to: DateOnly;

  priceRoomTitle: string;
  priceRoom: { name: string; value: number }[];

  priceCureTitle: string;
  priceCure: { name: string; value: number }[];

  priceTotalTitle: string;
  priceTotal: number;

  discountTitle: string;
  discountCode: string | undefined;
  discount: number | undefined;

  depositTitle: string;
  deposit: number;

  taxInformation: string;

  promotionTitle: string;
  onPromoChange: (value: string) => void;
}

export function PriceSummary(props: IPriceSummaryProps) {
  return (
    <div className="space-y-2">
      <span className="flex justify-between">
        <h3 className="font-semibold leading-7 text-gray-900">
          {props.fromTitle}:
        </h3>

        <span>{format(props.from, 'dd.MM.yyyy')}</span>
      </span>

      <span className="flex justify-between">
        <h3 className="font-semibold leading-7 text-gray-900">
          {props.toTitle}:
        </h3>

        <span>{format(props.to, 'dd.MM.yyyy')}</span>
      </span>

      <hr></hr>

      <span className="flex flex-col">
        <h3 className="font-semibold leading-7 text-gray-900">
          {props.priceRoomTitle}:
        </h3>

        <ul className="list list-inside list-disc">
          {props.priceRoom.map((room, idx) => {
            return (
              <li key={idx} className="flex gap-2 justify-between">
                <span>
                  {room.name}
                  {': '}
                </span>
                <span className="text-nowrap">
                  {room.value}
                  {' €'}
                </span>
              </li>
            );
          })}
        </ul>
      </span>

      <span className="flex flex-col">
        <h3 className="font-semibold leading-7 text-gray-900">
          {props.priceCureTitle}:
        </h3>

        <ul className="list list-inside list-disc">
          {props.priceCure.map((cure, idx) => {
            return (
              <li key={idx} className="flex gap-2 justify-between">
                <span>
                  {cure.name}
                  {': '}
                </span>
                <span className="text-nowrap">
                  {cure.value}
                  {' €'}
                </span>
              </li>
            );
          })}

          {props.priceCure.length === 0 && <li>-</li>}
        </ul>
      </span>

      <hr />

      {props.discount && (
        <>
          <span className="flex justify-between">
            <h3 className="font-semibold leading-7 text-gray-900">
              {props.discountTitle}: {props.discountCode}
            </h3>

            <span className="font-bold text-nowrap">{`-${props.discount} €`}</span>
          </span>

          <hr />
        </>
      )}

      <span className="flex justify-between">
        <h3 className="font-semibold leading-7 text-gray-900">
          {props.priceTotalTitle}:
        </h3>

        <span className="font-bold text-nowrap">{`${props.priceTotal} €`}</span>
      </span>

      <span className="flex justify-between">
        <h3 className="font-semibold leading-7 text-gray-900">
          {props.depositTitle}:
        </h3>

        <span className="font-bold text-nowrap">{`${props.deposit} €`}</span>
      </span>

      <hr />

      <PromotionInput
        title={props.promotionTitle}
        onChange={(data) => props.onPromoChange(data)}
      ></PromotionInput>

      <span className="flex justify-between text-gray-900 text-sm">
        {props.taxInformation}
      </span>
    </div>
  );
}

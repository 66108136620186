import { IGaItemDto, DefaultGaItemDto } from '../model/analytics';

export interface IAllMapToAnalyticsProps {
  id: string;
  internalName: string;
  gaItemCategory: string | null;
  gaItemCategory2: string | null;
  gaItemCategory3: string | null;
  gaItemCategory4: string | null;
  gaItemCategory5: string | null;
  gaItemVariant: string | null;
}

export function MapAllToAnalyticsItem(
  props: IAllMapToAnalyticsProps,
  singlePrice: number | undefined = undefined,
  quantity: number | undefined = undefined,
  item_list_name: string | undefined = undefined
): IGaItemDto {
  return {
    ...DefaultGaItemDto,
    item_id: props.id,
    item_name: props.internalName,
    item_category: props.gaItemCategory ?? undefined,
    item_category2: props.gaItemCategory2 ?? undefined,
    item_category3: props.gaItemCategory3 ?? undefined,
    item_category4: props.gaItemCategory4 ?? undefined,
    item_category5: props.gaItemCategory5 ?? undefined,
    item_list_name: item_list_name,
    item_variant: props.gaItemVariant ?? undefined,
    price: singlePrice,
    quantity: quantity,
  };
}

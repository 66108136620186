import { useTranslation } from 'react-i18next';

export function DetoxPackage() {
  const { i18n } = useTranslation('offer-package');

  if (i18n.language === 'de') {
    return (
      <div className="bg-white border shadow-md rounded-md overflow-clip">
        <header className="bg-primary-600 px-4 py-5">
          <h2 className="text-white font-bold">
            Ihre perfekte Vorbereitung auf die Kur!
          </h2>
        </header>
        <div className="grid md:grid-cols-3">
          <div className="p-4">
            <img
              src="https://cdn.mandira-ayurveda.at/images/products/2020/detox-pack.jpg"
              alt="Detox-Package"
            ></img>
          </div>

          <div className="p-4 md:col-span-2">
            <h3 className="font-bold">
              Bereiten Sie sich bereits zu Hause optimal auf Ihre Kur vor!
            </h3>
            <br />
            <p>
              Hierfür empfehlen wir Ihnen unser{' '}
              <strong>Pre-Detox-Paket!</strong>
            </p>
            <p>
              <strong>Unser Premium Paket</strong> (inkl. Buch mit vielen Tipps,
              Rezeptwelt, Yogaübungen uvm., Ablaufplan, Mandira-Maßband,
              Agni-Plus, Wellintest, Livell und Mibiwell):{' '}
            </p>
            <p>
              <strong>Pre Detox Plus um € 138</strong>
            </p>
            <p>oder</p>
            <p>
              <strong>Unser Basic Paket</strong> (inkl. Buch mit vielen Tipps,
              Rezeptwelt, Yogaübungen uvm., Ablaufplan, Mandira-Maßband,
              Agni-Plus, Wellintest & Livell):{' '}
            </p>
            <p>
              <strong>Pre Detox Basic um € 89 zzgl. Versandkosten</strong>
            </p>
            <br />
            <p>
              Kontaktieren Sie uns gerne für nähere Informationen telefonisch
              unter{' '}
              <a href="tel:+4344442801" className="hover:underline">
                +43 3333 2801
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border shadow-md rounded-md overflow-clip">
      <header className="bg-primary-600 px-4 py-5">
        <h2 className="text-white font-bold">
          Your perfect preparation for the cure!
        </h2>
      </header>
      <div className="grid md:grid-cols-3">
        <div className="p-4">
          <img
            src="https://cdn.mandira-ayurveda.at/images/products/2020/detox-pack.jpg"
            alt="Detox-Package"
          ></img>
        </div>

        <div className="p-4 md:col-span-2">
          <h3 className="font-bold">
            Prepare yourself optimally for your cure at home!{' '}
          </h3>
          <br />
          <p>
            For this we recommend our <strong>Pre-Detox-Paket!</strong>
          </p>
          <p>
            <strong>Our premium package</strong> (incl.zzz. Book with lots of
            tips, recipes, yoga exercises and much more, schedule, Mandira
            measuring tape, Agni-Plus, Wellintest, Livell and Mibiwell):{' '}
          </p>
          <p>
            <strong>Pre Detox Plus for € 138</strong>
          </p>
          <p>or</p>
          <p>
            <strong>Our basic package</strong> (incl. book with lots of tips,
            recipe world, yoga exercises and much more, schedule, Mandira
            measuring tape, Agni-Plus, Wellintest & Livell):{' '}
          </p>
          <p>
            <strong>Pre Detox Basic for € 89 plus shipping costs</strong>
          </p>
          <br />
          <p>
            Please contact us for further information by telephone{' '}
            <a href="tel:+4344442801" className="hover:underline">
              +43 3333 2801
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DetoxPackage;

import {
  ICheckAvailabilityResponseDto,
  ICheckRoomsAvailiableResponseDto,
  ISeasonCurePriceDto,
} from '../model';
import { DateOnly, axiosMod } from '../utility';
import { format } from 'date-fns';

export interface ICheckApi {
  getAvailability(
    month?: number,
    year?: number,
    numberOfRooms?: number,
    cure?: number,
    dog?: boolean,
    fixedFrom?: DateOnly
  ): Promise<ICheckAvailabilityResponseDto>;

  checkCures(
    from?: DateOnly,
    to?: DateOnly,
    cure?: number
  ): Promise<ISeasonCurePriceDto[]>;

  checkRooms(
    from?: DateOnly,
    to?: DateOnly,
    numberOfRooms?: number,
    dog?: boolean
  ): Promise<ICheckRoomsAvailiableResponseDto[]>;
}

export class CheckApi implements ICheckApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/check';
  }

  async getAvailability(
    month?: number,
    year?: number,
    numberOfRooms?: number,
    cure?: number,
    dog?: boolean,
    fixedFrom?: DateOnly
  ): Promise<ICheckAvailabilityResponseDto> {
    const response = await axiosMod().get<ICheckAvailabilityResponseDto>(
      this.endpointUrl + '/availability',
      {
        params: {
          month,
          year,
          numberOfRooms,
          cure,
          dog,
          fixedFrom: fixedFrom ? format(fixedFrom, 'yyyy-MM-dd') : undefined,
        },
      }
    );

    return response.data;
  }

  async checkCures(
    from?: DateOnly,
    to?: DateOnly,
    cure?: number
  ): Promise<ISeasonCurePriceDto[]> {
    const response = await axiosMod().get<ISeasonCurePriceDto[]>(
      this.endpointUrl + '/cures',
      {
        params: {
          from: from ? format(from, 'yyyy-MM-dd') : undefined,
          to: to ? format(to, 'yyyy-MM-dd') : undefined,
          cure,
        },
      }
    );

    return response.data;
  }

  async checkRooms(
    from?: DateOnly,
    to?: DateOnly,
    numberOfRooms?: number,
    dog?: boolean
  ): Promise<ICheckRoomsAvailiableResponseDto[]> {
    const response = await axiosMod().get<ICheckRoomsAvailiableResponseDto[]>(
      this.endpointUrl + '/rooms',
      {
        params: {
          from: from ? format(from, 'yyyy-MM-dd') : undefined,
          to: to ? format(to, 'yyyy-MM-dd') : undefined,
          numberOfRooms,
          dog,
        },
      }
    );

    return response.data;
  }
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  IReservationResponseDto,
  EmptyOfferReservationDto,
  IOfferReservationDto,
  offerReservationDtoValidation,
} from '../model';
import {
  getGaUserIdFromEmail,
  getHashedPhoneNumber,
  gtmEvent,
} from '../utility';
import { useTranslation } from 'react-i18next';
import { FormGroup, StyledButton, StyledInput, StyledTextarea } from '../ui';
import { useState } from 'react';
import {
  CureApi,
  ICureApi,
  INotificationService,
  IOfferReservationApi,
  IProductApi,
  OfferReservationApi,
  ProductApi,
} from '../services';
import { IGaItemDto, IGaPurchaseEventDto } from '../model/analytics';
import { IOfferDto } from '../model/IOfferDto';
import PaymentPicker from './payment-picker';
import classNames from 'classnames';
import Offer from './offer';
import { IOfferPackageDto } from '../model/IOfferPackageDto';
import { IRoomCategoryApi, RoomCategoryApi } from '../services/roomCategoryApi';
import { ITreatmentApi, TreatmentApi } from '../services/treatmentApi';
import { MapAllToAnalyticsItem } from '../mapping';

export interface IFormProps {
  notificationService: INotificationService;
  offer: IOfferDto;
  offerPackage: IOfferPackageDto;
}

export function Form(props: IFormProps) {
  const { t, i18n } = useTranslation('offer-package');
  const { offer, offerPackage } = props;

  const methods = useForm<IOfferReservationDto>({
    resolver: yupResolver(offerReservationDtoValidation(t)),
    defaultValues: {
      ...EmptyOfferReservationDto,
      contactLanguage: i18n.language,
      offerId: offer.id,
      acceptedCost: offer.price,
      acceptedDeposit: Math.ceil(offer.price / 3.0 / 50) * 50,
      contactFirstname: offerPackage.contactFirstname,
      contactLastname: offerPackage.contactLastname,
      contactMail: offerPackage.contactMail,
      contactPhone: offerPackage.contactPhone ?? '',
      contactTitle: offerPackage.contactTitle,
    },
    mode: 'onChange',
  });

  const offerReservationApiService: IOfferReservationApi =
    new OfferReservationApi();
  const cureApiService: ICureApi = new CureApi();
  const productApiService: IProductApi = new ProductApi();
  const roomCategoryApiService: IRoomCategoryApi = new RoomCategoryApi();
  const treatmentApiService: ITreatmentApi = new TreatmentApi();

  const [response, setResponse] = useState<IReservationResponseDto>();

  if (response) {
    return (
      <div className="mt-4 mb-10">
        <PaymentPicker response={response}></PaymentPicker>
      </div>
    );
  }

  const onSave = async (dto: IOfferReservationDto): Promise<void> => {
    try {
      const result = await offerReservationApiService.addReservation(dto);

      //TRACK
      if (offer) {
        const items: IGaItemDto[] = [];

        for (let index = 0; index < offer.offerCureList.length; index++) {
          const element = offer.offerCureList[index];
          const cure = await cureApiService.getById(element.cureId);

          items.push(
            MapAllToAnalyticsItem(
              {
                ...cure,
                id: `cure_${cure.id}`,
              },
              (element.offerPrice ?? element.price) / element.amount,
              element.amount
            )
          );
        }

        for (let index = 0; index < offer.offerProductList.length; index++) {
          const element = offer.offerProductList[index];
          const product = await productApiService.getById(element.productId);

          items.push(
            MapAllToAnalyticsItem(
              {
                ...product,
                id: `product_${product.id}`,
              },
              (element.offerPrice ?? element.price) / element.amount,
              element.amount
            )
          );
        }

        for (
          let index = 0;
          index < offer.offerRoomCategoryList.length;
          index++
        ) {
          const element = offer.offerRoomCategoryList[index];
          const room = await roomCategoryApiService.getById(
            element.roomCategoryId
          );

          items.push(
            MapAllToAnalyticsItem(
              {
                ...room,
                id: `room_${room.id}`,
              },
              element.offerPrice ?? element.price,
              1
            )
          );
        }

        for (let index = 0; index < offer.offerTreatmentList.length; index++) {
          const element = offer.offerTreatmentList[index];
          const treatment = await treatmentApiService.getById(
            element.treatmentId
          );

          items.push(
            MapAllToAnalyticsItem(
              {
                ...treatment,
                id: `treatment_${treatment.id}`,
              },
              element.offerPrice ?? element.price,
              1
            )
          );
        }

        const payload: IGaPurchaseEventDto = {
          currency: 'EUR',
          value: offer.price,
          transaction_id: `offer_reservation_${
            result.reservationId ?? 'unkown'
          }`,
          items: items,
        };

        gtmEvent('purchase', await getGaUserIdFromEmail(dto.contactMail), {
          ...payload,
          phone: getHashedPhoneNumber(dto.contactPhone),
        });
      }

      setResponse(result);
    } catch {
      props.notificationService.createNotification({
        title: 'ERROR',
        type: 'error',
        description: 'CALL REZEPTION',
        hideAfterSeconds: 10,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="mt-4 mb-32">
        <form
          onSubmit={methods.handleSubmit((data) => {
            return onSave(data).then(() => methods.reset(data));
          })}
          className="space-y-4"
        >
          <FormGroup title={t('contact.title')} addColon={true}>
            <div className="sm:col-span-3">
              <Controller
                name="contactMail"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('contact.mail'),
                    prefix: '✉',
                    inputType: 'email',
                  })
                }
              />
            </div>

            <div className="sm:col-span-3">
              <Controller
                name="contactPhone"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('contact.call'),
                    prefix: '📞',
                    placeholder: '+43',
                  })
                }
              />
            </div>
          </FormGroup>

          <FormGroup title={t('newsletter.title')}>
            <div className="sm:col-span-6">
              <Controller
                name="newsletter"
                control={methods.control}
                render={(s) => (
                  <button
                    type="button"
                    className={classNames(
                      'rounded-md border p-2 w-full text-left flex gap-2',
                      s.field.value &&
                        'text-green-800 border-green-800 bg-green-50',
                      !s.field.value &&
                        'text-gray-900 border-gray-300 bg-gray-50'
                    )}
                    onClick={() => s.field.onChange(!s.field.value)}
                  >
                    {s.field.value ? ' ☑  ' : ' □  '}
                    {t('newsletter.message')}
                  </button>
                )}
              />
            </div>
          </FormGroup>

          <FormGroup title={t('personal.title')} addColon={true}>
            <div className="sm:col-span-6 grid sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Controller
                  name="contactTitle"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.salutation'),
                      hint: '(optional)',
                    })
                  }
                />
              </div>
            </div>

            <div className="sm:col-span-3 lg:col-span-2">
              <Controller
                name="contactFirstname"
                control={methods.control}
                render={(s) =>
                  StyledInput({ ...s, title: t('personal.firstname') })
                }
              />
            </div>

            <div className="sm:col-span-3 lg:col-span-2">
              <Controller
                name="contactLastname"
                control={methods.control}
                render={(s) =>
                  StyledInput({ ...s, title: t('personal.lastname') })
                }
              />
            </div>

            <div className="sm:col-span-3">
              <Controller
                name="contactBirthday"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.birthday'),
                    inputType: 'date',
                    prefix: '🎂',
                  })
                }
              />
            </div>

            <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
              <div className="sm:col-span-3 lg:col-span-2">
                <Controller
                  name="contactStreet"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.street'),
                    })
                  }
                />
              </div>

              <div className="sm:col-span-2 lg:col-span-1">
                <Controller
                  name="contactStreetNr"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.number'),
                    })
                  }
                />
              </div>
            </div>

            <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
              <div className="sm:col-span-2 lg:col-span-1">
                <Controller
                  name="contactPostal"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.postal'),
                    })
                  }
                />
              </div>

              <div className="sm:col-span-3 lg:col-span-2">
                <Controller
                  name="contactCity"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.city'),
                    })
                  }
                />
              </div>
            </div>

            <div className="sm:col-span-4 lg:col-span-3">
              <Controller
                name="contactCountry"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.country'),
                  })
                }
              />
            </div>
          </FormGroup>

          <FormGroup
            title={t('message.title')}
            isOptional={true}
            description={t('message.description')}
          >
            <div className="sm:col-span-6">
              <Controller
                name="message"
                control={methods.control}
                render={(s) => StyledTextarea({ ...s, title: '' })}
              />
            </div>
          </FormGroup>

          <FormGroup title={t('summary.title')}>
            <div className="flex flex-col sm:col-span-6 2xl:col-span-4">
              <Offer offer={offer}></Offer>
            </div>

            <div className="sm:col-span-6">
              <Controller
                name="agbAccepted"
                control={methods.control}
                render={(s) => (
                  <button
                    type="button"
                    className={classNames(
                      'rounded-md border p-2 w-full text-left flex gap-2',
                      s.field.value &&
                        'text-green-800 border-green-800 bg-green-50',
                      !s.field.value &&
                        'text-gray-900 border-gray-300 bg-gray-50'
                    )}
                    onClick={() => s.field.onChange(!s.field.value)}
                  >
                    {s.field.value ? ' ☑  ' : ' □  '}
                    {t('agb.message')}
                  </button>
                )}
              />
            </div>

            <div className="sm:col-span-6">
              <Controller
                name="privacyPolicyAccepted"
                control={methods.control}
                render={(s) => (
                  <button
                    type="button"
                    className={classNames(
                      'rounded-md border p-2 w-full text-left flex gap-2',
                      s.field.value &&
                        'text-green-800 border-green-800 bg-green-50',
                      !s.field.value &&
                        'text-gray-900 border-gray-300 bg-gray-50'
                    )}
                    onClick={() => s.field.onChange(!s.field.value)}
                  >
                    {s.field.value ? ' ☑  ' : ' □  '}
                    {t('privacy-policy.message')}
                  </button>
                )}
              />
            </div>

            <div className="sm:col-span-6">
              <StyledButton
                type="submit"
                className={classNames(
                  methods.formState.isValid &&
                    'outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline'
                )}
                disabled={
                  !methods.formState.isValid || methods.formState.isSubmitting
                }
                design="primary"
              >
                {t('shared.book')}
              </StyledButton>
            </div>

            <span className="sm:col-span-6">
              <ul className="list list-inside list-disc">
                <li>{t('price.arrival')}</li>
                <li>{t('price.departure')}</li>
                <li>{t('price.notes')}</li>
                <li>{t('price.storno')}</li>
              </ul>
            </span>

            <div className="sm:col-span-6 flex flex-col gap-1">
              <span>
                {'* '}
                <a
                  href={
                    i18n.language === 'de'
                      ? 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agb-hotellerie.pdf'
                      : 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agbh-englisch.pdf'
                  }
                  className="underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('agb.title')}
                </a>
              </span>
              <span>
                {'** '}
                <a
                  href="https://www.mandira-ayurveda.at/datenschutz/"
                  className="underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('privacy-policy.title')}
                </a>
              </span>
            </div>
          </FormGroup>
        </form>
      </div>
    </FormProvider>
  );
}

export default Form;

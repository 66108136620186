import { useTranslation } from 'react-i18next';
import Header from './header';
import { StyledLink } from '../ui';

export function PaymentThankYouPage() {
  const { t } = useTranslation('common');

  return (
    <div>
      <Header></Header>

      <div className="container px-2">
        <div className="flex justify-center">
          <div className="mt-4 space-y-2 max-w-lg w-full">
            <h1 className="text-lg font-bold text-slate-800">
              {t('payment.finish')}
            </h1>

            <StyledLink href="https://www.mandira-ayurveda.at">
              {t('payment.action')}
            </StyledLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentThankYouPage;

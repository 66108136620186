import { useTranslation } from 'react-i18next';
import Header from './header';
import { INotificationService, NotificationService } from '../services';
import Overlay from '../ui/overlay';
import { StyledLink } from '../ui';

const notificationService: INotificationService = new NotificationService();

export function Finish() {
  const { t } = useTranslation();

  return (
    <div>
      <Overlay service={notificationService}></Overlay>

      <Header></Header>

      <div className="container mt-6 space-y-4 text-center">
        <h1 className="text-2xl font-bold">{t('finish.title')}</h1>

        <p>{t('finish.description')}</p>

        <StyledLink design="primary" href="https://www.mandira-ayurveda.at">
          {t('finish.action')}
        </StyledLink>

        <div className="flex justify-center items-center">
          <div id="hotjar-fb-selector"></div>
        </div>
      </div>
    </div>
  );
}

export default Finish;

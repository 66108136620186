import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function LangRedirect() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const hasOfferReservation = searchParams.get('offer-reservation');
  const hasOfferPackage = searchParams.get('offer-package');
  const notrack = searchParams.get('notrack');
  const hasTreatments = searchParams.get('treatment');
  const hasRooms = searchParams.get('room');

  let path = '';

  if (Array.from(searchParams.keys()).includes('voucher')) {
    path += `/voucher`;
  }
  if (Array.from(searchParams.keys()).includes('reservation')) {
    path += `/reservation`;
  }

  let search: string | undefined = undefined;
  if (hasTreatments || hasRooms || hasOfferPackage || hasOfferReservation) {
    search = `?`;
    let hasSearch = false;

    if (hasOfferPackage) {
      path += `/offer-package`;
      search = search + `offer-package=${encodeURIComponent(hasOfferPackage)}`;
      hasSearch = true;
    }

    if (hasOfferReservation) {
      path += `/offer-reservation`;
      search =
        search + `offer-reservation=${encodeURIComponent(hasOfferReservation)}`;
      hasSearch = true;
    }

    if (hasTreatments) {
      search = search + `treatment=${encodeURIComponent(hasTreatments)}`;
      hasSearch = true;
    }

    if (hasRooms) {
      if (hasTreatments) {
        search = search + '&';
      }
      search = search + `room=${encodeURIComponent(hasRooms)}`;
      hasSearch = true;
    }

    if (notrack === 'yes') {
      if (hasSearch) {
        search = search + '&';
      }

      search = search + 'notrack=yes';
    }
  }

  useEffect(() => {
    navigate(
      {
        pathname: `/${i18n.language}${path}`,
        search,
      },
      { replace: true }
    );
  }, []);

  return null;
}

export default LangRedirect;

import { useTranslation } from 'react-i18next';

export function Introduction(props: {
  firstname: string;
  lastname: string;
  isOffer: boolean;
}) {
  const { t } = useTranslation('offer-package');

  return (
    <div className="print:hidden">
      <div className="flex flex-col gap-2 relative w-full h-[45rem] md:h-[40rem] items-center text-center">
        <img
          src="https://cdn.mandira-ayurveda.at/images/resort/2020/spa-waitingarea.jpg"
          alt="Wartebereich"
          className="object-cover w-full h-full object-top"
        ></img>

        <div className="w-full h-full absolute flex flex-col items-center justify-center gap-4 container max-w-3xl px-2 md:px-0">
          <div className="p-4 rounded-lg bg-black bg-opacity-25">
            <h1 className="text-2xl sm:text-3xl md:text-4xl text-white">
              {t('introduction.title')}
            </h1>
            <span className="text-lg sm:text-xl md:text-2xl text-white">
              {t('introduction.description')}
            </span>
          </div>
        </div>
      </div>

      <div className="text-xl container max-w-3xl px-2 sm:px-0 mt-6">
        {props.isOffer
          ? t('introduction.offer')
          : t('introduction.reservation')}
        : {props.lastname} {props.firstname}
      </div>
    </div>
  );
}

export default Introduction;

import { DateOnly } from '../utility';

export enum PromotionType {
  fixedAll = 1,
  percentageAll = 2,
}

export interface IPromotionDto {
  id: number;

  code: string;
  minStayDays: number;
  mustHaveCure: boolean;
  redeemableFrom: DateOnly;
  redeemableTo: DateOnly;
  bookableFrom: DateOnly | null;
  bookableTo: DateOnly | null;
  type: PromotionType;
  value: number;
  treatmentsMustInclude: number[] | null;

  typeVoucher: PromotionType | null;
  valueVoucher: number | null;
}

export const EmptyPromotionDto: IPromotionDto = {
  id: 0,

  code: '',
  minStayDays: 3,
  mustHaveCure: false,
  redeemableFrom: new Date(),
  redeemableTo: new Date(),
  bookableFrom: null,
  bookableTo: null,
  type: PromotionType.fixedAll,
  value: 0,
  treatmentsMustInclude: null,

  typeVoucher: null,
  valueVoucher: null,
};

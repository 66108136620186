export interface IURLParams {
  [key: string]: string | number | boolean | Date;
}

export function parseURLSearchParams<T>(search: URLSearchParams): Partial<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paramsObject: any = {};

  for (const [key, value] of search.entries()) {
    if (!isNaN(Number(value))) {
      paramsObject[key] = Number(value);
    } else if (
      value.toLowerCase() === 'true' ||
      value.toLowerCase() === 'false'
    ) {
      paramsObject[key] = value.toLowerCase() === 'true';
    } else {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        paramsObject[key] = date;
      } else {
        paramsObject[key] = value;
      }
    }
  }

  return paramsObject as Partial<T>;
}

export function stringifyURLSearchParams<T>(
  params: Partial<T>
): URLSearchParams {
  const urlSearchParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];

    if (typeof value === 'number' || typeof value === 'boolean') {
      urlSearchParams.set(key, value.toString());
    } else if (value instanceof Date) {
      urlSearchParams.set(key, value.toISOString());
    } else if (typeof value === 'string') {
      urlSearchParams.set(key, value);
    }
  }

  return urlSearchParams;
}

export function addOrReplaceFilter<T>(
  existingFilter: string | undefined,
  filterExpressionToAdd: string,
  value: T | undefined | never
): string {
  const filterItems = existingFilter?.split(',');
  let newFilter = '';

  if (filterItems) {
    filterItems.forEach((item) => {
      if (!item.startsWith(filterExpressionToAdd)) {
        newFilter += ',' + item;
      }
    });
  }

  if (value !== undefined && value !== null) {
    newFilter += ',' + filterExpressionToAdd + value.toString();
  }
  return newFilter.substring(1);
}

export function parseIntParam(
  value: string | undefined | null
): number | undefined {
  const parsedParam = Number.parseInt(value ?? '');

  if (Number.isNaN(parsedParam)) {
    return undefined;
  }

  return parsedParam;
}

export function parseIntParamOrThrow(value: string | undefined | null): number {
  const parsedParam = parseIntParam(value);

  if (!parsedParam) {
    throw Error('Parameter cloud not be parsed');
  }

  return parsedParam;
}

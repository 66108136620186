import { useTranslation } from 'react-i18next';
import { StyledLink } from '../ui';
import { IReservationResponseDto } from '../model';

export interface IPaymentPickerProps {
  response: IReservationResponseDto;
}

export function PaymentPicker(props: IPaymentPickerProps) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center">
      <div className="space-y-2 max-w-lg w-full">
        {(props.response.hasError || props.response.hasErrorPayment) && (
          <h1 className="text-lg text-slate-800">
            {t('voucher.finish.api-error')}
          </h1>
        )}
        {props.response.checkoutUrl && (
          <div className="space-y-2">
            <h1 className="text-lg text-slate-800">
              {t('voucher.finish.pay-deposit-title')}
            </h1>
            <h2 className="text-slate-700">
              {t('voucher.finish.pay-deposit-description')}
            </h2>

            <StyledLink design="primary" href={props.response.checkoutUrl}>
              {t('voucher.finish.pay-deposit')}
            </StyledLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentPicker;

import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOfferRowProps {}

export function OfferRow(props: IOfferRowProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full text-center border-b p-1 mb-4 bg-[#C9E0B4]">
      <span className="text-primary-900">{t('offer-row.title')}</span>
    </div>
  );
}

import Form from './form';
import Header from './header';
import {
  INotificationService,
  IPromotionApi,
  NotificationService,
  PromotionApi,
} from '../services';
import Overlay from '../ui/overlay';
import classNames from 'classnames';
import { IVoucherApi, VoucherApi } from '../services/voucherApi';
import { IVoucherTemplateDto } from '../model';
import { useState } from 'react';
import { StyledDialog } from '../ui';
import { useTranslation } from 'react-i18next';

const notificationService: INotificationService = new NotificationService();
const voucherApiService: IVoucherApi = new VoucherApi();
const promotionApiService: IPromotionApi = new PromotionApi();

export function Voucher() {
  const { t } = useTranslation();

  const [pdfUrl, setPdfUrl] = useState<string>();

  const onDraft = async (data: Partial<IVoucherTemplateDto>) => {
    const responseBlob = await voucherApiService.renderDraftVoucher({
      contactLanguage: 'de',
      value: 100.0,
      text: '',
      title: '',
      ...data,
    });

    setPdfUrl(URL.createObjectURL(responseBlob));
  };

  return (
    <div>
      <Overlay service={notificationService}></Overlay>

      <Header></Header>

      <StyledDialog
        onClose={() => setPdfUrl(undefined)}
        isOpen={pdfUrl !== undefined}
      >
        <div className="h-full">
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="500px"
              style={{ border: 'none' }}
              title="PDF Viewer"
            ></iframe>
          )}
        </div>
      </StyledDialog>

      <div className="container px-2 mb-12">
        <div
          id="teaser"
          className={classNames(
            'my-4',
            'flex flex-col items-center text-center space-y-2',
            '[&>*]:max-w-2xl'
          )}
        >
          <h2 className="text-3xl">{t('voucher.header.title')}</h2>
          <h3 className="text-xl text-gray-800">
            {t('voucher.header.subtitle')}
          </h3>
          <p className="text-gray-700 flex flex-col">
            <span>{t('voucher.header.description')}</span>
            <span className="mt-1">{t('voucher.header.signature')}</span>
          </p>
        </div>

        <Form
          voucherApi={voucherApiService}
          promotionApi={promotionApiService}
          notificationService={notificationService}
          onDraftClick={(data) => onDraft(data)}
        ></Form>
      </div>
    </div>
  );
}

export default Voucher;

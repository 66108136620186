import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IStepperContext, StepperContext, StyledButton } from '../../ui';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPersonPickerProps {}

const possiblePersonCounts = Array.from({ length: 4 }, (_, i) => i + 1);

export function PersonPicker(props: IPersonPickerProps) {
  const { t } = useTranslation();
  const methods = useFormContext();
  const stepperContext = useContext<IStepperContext>(StepperContext);

  const [rooms, setRooms] = useState<number[]>([1]);

  const personWatch: number = methods.watch('personCount');
  const roomWatch: number = methods.watch('roomCount');

  useEffect(() => {
    if (personWatch === 1) {
      methods.setValue('roomCount', 1);
      setRooms([1]);
    } else if (personWatch === 2) {
      if (roomWatch > 2) {
        methods.setValue('roomCount', 1);
      }

      setRooms([1, 2]);
    } else {
      const min = Math.max(1, Math.round(personWatch / 2));

      const newRooms = Array.from(
        { length: personWatch },
        (_, i) => i + 1
      ).filter((ct) => ct >= min);

      if (!newRooms.includes(roomWatch)) {
        methods.setValue('roomCount', min);
      }
      setRooms(newRooms);
    }

    //reset cures -> persons have changes
    methods.setValue('cureList', []);
  }, [personWatch]);

  useEffect(() => {
    methods.setValue('roomList', []);
    methods.setValue('from', null);
    methods.setValue('to', null);
  }, [roomWatch]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="container px-2 mb-12">
        <div
          id="teaser"
          className={classNames(
            'my-4',
            'flex flex-col items-center text-center space-y-2',
            '[&>*]:max-w-2xl'
          )}
        >
          <h2 className="text-3xl">{t('reservation.header.title')}</h2>
          <h3 className="text-xl text-gray-800">
            {t('reservation.header.subtitle')}
          </h3>
          {/* <p className="text-gray-700 flex flex-col">
            <span>{t('voucher.header.description')}</span>
            <span className="mt-1">{t('voucher.header.signature')}</span>
          </p> */}
        </div>
      </div>

      <div className="space-y-2 max-w-lg w-full">
        <div className="space-y-2">
          <h2 className="font-medium text-gray-700">
            {t('reservation.person-picker.person-count')}
          </h2>

          {personWatch > 2 && (
            <p className="text-sm max-w-lg">
              {t('reservation.person-picker.high-person.disclaimer')}
              <a
                href="https://anfrage.mandira-ayurveda.at"
                className=" underline text-primary-600"
                rel="noreferrer"
                target="_blank"
              >
                {t('reservation.person-picker.high-person.enquiry')}
              </a>
            </p>
          )}

          <div className="flex gap-1">
            {possiblePersonCounts.map((ct) => (
              <StyledButton
                key={ct}
                design={personWatch === ct ? 'primary' : 'default'}
                type="button"
                onClick={() => methods.setValue('personCount', ct)}
              >
                {ct}
              </StyledButton>
            ))}
          </div>
        </div>

        <div className={classNames('space-y-2', personWatch === 1 && 'hidden')}>
          <h2 className="font-medium text-gray-700">
            {t('reservation.person-picker.room-count')}
          </h2>

          <div className="flex gap-1">
            {rooms.map((ct) => (
              <StyledButton
                key={ct}
                design={roomWatch === ct ? 'primary' : 'default'}
                type="button"
                onClick={() => methods.setValue('roomCount', ct)}
              >
                {ct}
              </StyledButton>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-2 md:flex items-center justify-center gap-2 bottom-0 right-0 p-4 fixed bg-white w-full">
          <StyledButton
            type="button"
            design="primary"
            rootClassName="w-full col-span-2 md:w-48 h-12 md:h-auto"
            className="w-full outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline"
            onClick={() =>
              stepperContext.onNextStep && stepperContext.onNextStep()
            }
          >
            <span className="flex justify-center items-center w-full h-full">
              {t('reservation.forward')}
            </span>
          </StyledButton>
        </div>
      </div>
    </div>
  );
}

export default PersonPicker;

import { AtSymbolIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { StyledLink } from '../ui';
import { useTranslation } from 'react-i18next';
import { OfferRow } from '../offer-row';

export function Header() {
  const { t } = useTranslation();

  return (
    <header className="bg-white pb-4">
      <OfferRow></OfferRow>

      <div className="container px-2 flex justify-between items-center">
        <a href="https://www.mandira-ayurveda.at">
          <img
            className="h-16"
            alt="Logo Mandira"
            src="https://cdn.mandira-ayurveda.at/images/logo/2024/dark_cyan_text_black.png?ref_type=heads"
          ></img>
          <span className="sr-only">{t('header.to-homepage')}</span>
        </a>

        <h1 className="text-2xl text-white tracking-wider">
          {t('header.title')}
        </h1>

        <div id="header-actions" className="flex gap-2">
          <StyledLink
            href="tel:+4333332801"
            classNames="flex items-center gap-1"
          >
            <PhoneIcon className="w-4 h-4"></PhoneIcon> {t('header.call')}
          </StyledLink>

          <StyledLink
            href="mailto:info@mandira-ayurveda.at"
            classNames="hidden md:flex items-center gap-1"
          >
            <AtSymbolIcon className="w-4 h-4"></AtSymbolIcon> {t('header.mail')}
          </StyledLink>
        </div>
      </div>
    </header>
  );
}

export default Header;

import { IOfferReservationDto, IReservationResponseDto } from '../model';
import { axiosMod } from '../utility';

export interface IOfferReservationApi {
  addReservation(dto: IOfferReservationDto): Promise<IReservationResponseDto>;

  getById(id: string): Promise<IOfferReservationDto>;

  payDeposit(id: string): Promise<IReservationResponseDto>;

  track(id: string): Promise<void>;
}

export class OfferReservationApi implements IOfferReservationApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/offer-reservation';
  }

  async addReservation(
    dto: IOfferReservationDto
  ): Promise<IReservationResponseDto> {
    const response = await axiosMod().post<IReservationResponseDto>(
      this.endpointUrl + '/guest',
      dto
    );

    return response.data;
  }

  async getById(id: string): Promise<IOfferReservationDto> {
    const response = await axiosMod().get<IOfferReservationDto>(
      `${this.endpointUrl}/${id}`
    );

    return response.data;
  }

  async payDeposit(id: string): Promise<IReservationResponseDto> {
    const response = await axiosMod().post<IReservationResponseDto>(
      `${this.endpointUrl}/${id}/deposit`,
      {}
    );

    return response.data;
  }

  async track(id: string): Promise<void> {
    const response = await axiosMod().post<void>(
      `${this.endpointUrl}/${id}/track`,
      {}
    );

    return response.data;
  }
}

import { IOfferDto } from '../model/IOfferDto';
import { axiosMod } from '../utility';

export interface IOfferApi {
  getById(id: string): Promise<IOfferDto>;
}

export class OfferApi implements IOfferApi {
  private endpointUrl: string;

  constructor() {
    this.endpointUrl = 'https://mandira.mauracher.cc/api/offer';
  }

  async getById(id: string): Promise<IOfferDto> {
    const response = await axiosMod().get<IOfferDto>(
      `${this.endpointUrl}/${id}`
    );

    return response.data;
  }
}

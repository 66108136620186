import * as yup from 'yup';
import { TFunction } from 'i18next';
import { IRoomCategoryDto } from '../IRoomCategoryDto';
import { IReservationDto } from '../IReservationDto';
import { DateOnly } from '../../utility';
import { roomCategoryDtoValidation } from './room-category-dto-validation';
import { ICureDto } from '../ICureDto';
import { cureDtoValidation } from './cure-dto-validation';
import { IReservationRoomDto } from '../IReservationRoomDto';
import { IReservationCureDto } from '../IReservationCureDto';
import { IPromotionDto } from '../IPromotionDto';
import { promotionDtoValidation } from './promotion-dto-validation';

export function reservationCureDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IReservationCureDto> {
  return yup
    .object({
      id: yup.number().required(),
      cure: yup.mixed<ICureDto>(cureDtoValidation(translation)).required(),
    })
    .required();
}

export function reservationRoomDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IReservationRoomDto> {
  return yup
    .object({
      id: yup.number().required(),
      occupancy: yup.number().required(),
      roomCategory: yup
        .mixed<IRoomCategoryDto>(roomCategoryDtoValidation(translation))
        .required(),
    })
    .required();
}

export function reservationDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IReservationDto> {
  return yup
    .object({
      id: yup.number().required(),

      from: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .nonNullable(translation('errors.required'))
        .defined(),

      to: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .nonNullable(translation('errors.required'))
        .defined(),

      personCount: yup.number().required(translation('errors.required')),
      roomCount: yup.number().required(translation('errors.required')),

      roomList: yup
        .array()
        .of<IReservationRoomDto>(reservationRoomDtoValidation(translation))
        .required(translation('errors.required')),

      cureList: yup
        .array()
        .of<IReservationCureDto>(reservationCureDtoValidation(translation))
        .required(translation('errors.required')),

      acceptedCost: yup.number().required(),
      acceptedDeposit: yup.number().required(),

      contactLanguage: yup.string().oneOf(['de', 'en']).required(),
      contactMail: yup
        .string()
        .email(translation('errors.required'))
        .required(translation('errors.required')),
      contactBirthday: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .nonNullable(translation('errors.required'))
        .defined(),
      contactPhone: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactTitle: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .nullable()
        .defined(),
      contactFirstname: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactLastname: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactStreet: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactStreetNr: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactPostal: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactCity: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),
      contactCountry: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .required(translation('errors.required')),

      agbAccepted: yup.bool().required().oneOf([true]),
      privacyPolicyAccepted: yup.bool().required().oneOf([true]),
      newsletter: yup.bool().required(),

      promotion: yup
        .mixed<IPromotionDto>(promotionDtoValidation(translation))
        .defined(translation('errors.required'))
        .nullable(),

      message: yup
        .string()
        .trim()
        .transform((e) => (e === '' ? null : e))
        .nullable()
        .defined(),
    })
    .required();
}

import classNames from 'classnames';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export interface StyledButtonProps {
  onClick?: () => void;
  onClickAsync?: () => Promise<void>;

  design?: 'default' | 'primary' | 'error';
  type: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  rootClassName?: string;
  className?: string;
  tooltip?: string;

  children?: React.ReactNode;
}

export function StyledButton(props: StyledButtonProps) {
  const { type, onClick, onClickAsync, children, disabled } = props;

  const [isClicking, setIsClicking] = useState<boolean>(false);

  const onClickInvoke = async () => {
    if (onClick) {
      return onClick();
    }

    if (onClickAsync) {
      try {
        setIsClicking(true);

        await onClickAsync();
      } finally {
        setIsClicking(false);
      }
    }
  };

  const isPrimary = props.design && props.design === 'primary';
  const isError = props.design && props.design === 'error';

  const isDefault = !isPrimary && !isError;

  const tooltipId = uuidv4();

  return (
    <div
      className={classNames(
        'inline-flex',
        props.rootClassName && props.rootClassName
      )}
    >
      {props.tooltip && <Tooltip id={tooltipId}></Tooltip>}

      <button
        type={type}
        onClick={() => onClickInvoke()}
        disabled={isClicking || disabled}
        data-tooltip-id={tooltipId}
        data-tooltip-content={props.tooltip}
        className={classNames(
          'flex rounded-md border text-sm font-medium px-4 py-2 shadow-sm select-none', //frame + alignments
          'disabled:opacity-50', //effects
          !isDefault && 'border-transparent', //default on desgined buttons
          !isDefault && 'focus:outline-none focus:ring-2 focus:ring-offset-2', //effects on designed buttons
          /** STYLE */
          isPrimary && 'bg-primary-600 text-white',
          isPrimary &&
            !disabled &&
            'hover:bg-primary-700 focus:ring-primary-800',
          isError && 'bg-red-800 text-white',
          isError && !disabled && 'hover:bg-red-900 focus:ring-red-700',
          isDefault && 'bg-white text-black border-gray-300',
          isDefault && !disabled && 'hover:bg-gray-50',
          props.className && props.className
        )}
      >
        {children}
      </button>
    </div>
  );
}

export default StyledButton;

/* eslint-disable @typescript-eslint/no-explicit-any */
export function gtmEvent(
  eventname: string,
  userId: string | undefined = undefined,
  props: any | undefined = undefined
) {
  const datalayer = (window as { [key: string]: any })['dataLayer'];

  if (datalayer) {
    if (userId) {
      if (props) {
        datalayer.push({
          event: eventname,
          user_id: userId,
          ...props,
        });
      } else {
        datalayer.push({
          event: eventname,
          user_id: userId,
          ...props,
        });
      }
    } else {
      if (props) {
        datalayer.push({
          event: eventname,
          ...props,
        });
      } else {
        datalayer.push({
          event: eventname,
          ...props,
        });
      }
    }
  }
}

async function sha256(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message.toLowerCase().trim());
  return window.crypto.subtle.digest('SHA-256', data);
}

function bufferToHexEncoded(input: ArrayBuffer) {
  const hash_array = Array.from(new Uint8Array(input));
  // Return a hex-encoded string.
  return hash_array.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export async function getShaStringFromValue(value: string): Promise<string> {
  const hash = await sha256(value);
  return bufferToHexEncoded(hash);
}

export async function getGaUserIdFromEmail(email: string): Promise<string> {
  return await getShaStringFromValue(email.trim().toLowerCase());
}

export async function getHashedPhoneNumber(
  value: string | null
): Promise<string | null> {
  if (value) {
    const prepared = value.trim().toLowerCase();

    if (prepared.startsWith('+')) {
      return await getShaStringFromValue(`+${prepared.replace(/\D/g, '')}`);
    }
  }

  return null;
}
export async function getHashedValue(
  value: string | null
): Promise<string | null> {
  if (value) {
    return await getShaStringFromValue(value.trim().toLowerCase());
  }

  return null;
}

export function getGaClientId(): string | null {
  const cookie: any = {};
  document.cookie.split(';').forEach((element) => {
    const splitCookie = element.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  if (cookie['_ga']) {
    return cookie['_ga'].substring(6);
  }

  return null;
}

export function getGaSessionId(): number | null {
  const cookie: any = {};
  document.cookie.split(';').forEach((element) => {
    const splitCookie = element.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });

  if (cookie['_ga_YEGLX90SDD']) {
    const parts = cookie['_ga_YEGLX90SDD'].split('.');
    const parsed = Number(parts[2]);

    if (!Number.isNaN(parsed)) {
      return parsed;
    }
  }

  return null;
}

import { Control, Controller } from 'react-hook-form';
import { IEnquiryDto } from '../model';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledInput } from '../ui';
import { useState } from 'react';

export interface IAddressFormProps {
  control: Control<IEnquiryDto, any>;
}

export function AddressForm(props: IAddressFormProps) {
  const { t } = useTranslation();
  const { control } = props;

  const [showAddress, setShowAddress] = useState<boolean>(false);

  return (
    <>
      {!showAddress && (
        <div className="sm:col-span-6">
          <StyledButton type="button" onClick={() => setShowAddress(true)}>
            {t('personal.enter-address')}
            <span className="text-slate-600 text-sm ms-1">{' (optional)'}</span>
          </StyledButton>
        </div>
      )}

      {showAddress && (
        <>
          <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
            <div className="sm:col-span-3 lg:col-span-2">
              <Controller
                name="contactStreet"
                control={control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.street'),
                    hint: '(optional)',
                  })
                }
              />
            </div>

            <div className="sm:col-span-2 lg:col-span-1">
              <Controller
                name="contactStreetNr"
                control={control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.number'),
                    hint: '(optional)',
                  })
                }
              />
            </div>
          </div>

          <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
            <div className="sm:col-span-2 lg:col-span-1">
              <Controller
                name="contactPostal"
                control={control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.postal'),
                    hint: '(optional)',
                  })
                }
              />
            </div>

            <div className="sm:col-span-3 lg:col-span-2">
              <Controller
                name="contactCity"
                control={control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.city'),
                    hint: '(optional)',
                  })
                }
              />
            </div>
          </div>

          <div className="sm:col-span-4 lg:col-span-3">
            <Controller
              name="contactCountry"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.country'),
                  hint: '(optional)',
                })
              }
            />
          </div>
        </>
      )}
    </>
  );
}

export default AddressForm;

import { ICheckRoomsAvailiableResponseDto } from '../model';
import { IGaItemDto, DefaultGaItemDto } from '../model/analytics';

export function MapRoomToAnalyticsItem(
  roomPrice: ICheckRoomsAvailiableResponseDto,
  occupancy: number,
  quantity: number | undefined = undefined,
  item_list_name: string | undefined = undefined
): IGaItemDto {
  return {
    ...DefaultGaItemDto,
    item_id: `room_${roomPrice.roomCategory.id}`,
    item_name: roomPrice.roomCategory.internalName,
    item_category: roomPrice.roomCategory.gaItemCategory ?? undefined,
    item_category2: roomPrice.roomCategory.gaItemCategory2 ?? undefined,
    item_category3: roomPrice.roomCategory.gaItemCategory3 ?? undefined,
    item_category4: roomPrice.roomCategory.gaItemCategory4 ?? undefined,
    item_category5: roomPrice.roomCategory.gaItemCategory5 ?? undefined,
    item_list_name: item_list_name,
    item_variant: roomPrice.roomCategory.gaItemVariant ?? undefined,
    price:
      occupancy === 1
        ? roomPrice.singleRoomPriceTotal
        : roomPrice.doubleRoomPriceTotal,
    quantity: quantity,
  };
}

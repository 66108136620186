import * as yup from 'yup';
import { TFunction } from 'i18next';
import { IRoomCategoryDto } from '../IRoomCategoryDto';
import { IRoomCategoryTranslationDto } from '../IRoomCategoryTranslationDto';

export function roomCategoryTranslationDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IRoomCategoryTranslationDto> {
  return yup
    .object({
      id: yup.number().required(),
      langCode: yup.string().oneOf(['de', 'en']).required(),
      title: yup.string().required(),

      subtitle: yup.string().defined().nullable(),
      teaser: yup.string().defined().nullable(),
      description: yup.string().defined().nullable(),
      features: yup.array().nullable().defined(),
    })
    .required();
}

export function roomCategoryDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IRoomCategoryDto> {
  return yup
    .object({
      id: yup.number().required(),

      internalName: yup.string().required(),
      sizeSquareMeter: yup.number().required(),
      minOccupancy: yup.number().required().min(1),
      maxOccupancy: yup.number().required().min(1),

      allowDogs: yup.bool().required(),
      isActive: yup.bool().required(),
      imageLinks: yup.array().nullable().defined(),

      gaItemCategory: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory1: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory2: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory3: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory4: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory5: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemVariant: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),

      roomCategoryTranslationList: yup
        .array()
        .of<IRoomCategoryTranslationDto>(
          roomCategoryTranslationDtoValidation(translation)
        )
        .required(),
    })
    .defined();
}

import * as yup from 'yup';
import { TFunction } from 'i18next';
import { ICureTranslationDto } from '../ICureTranslationDto';
import { ICureDto } from '../ICureDto';
import { ICureCategoryDto } from '../ICureCategoryDto';

export function cureTranslationDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<ICureTranslationDto> {
  return yup
    .object({
      id: yup.number().required(),
      langCode: yup.string().oneOf(['de', 'en']).required(),
      title: yup.string().required(),

      subtitle: yup.string().defined().nullable(),
      teaser: yup.string().defined().nullable(),
      description: yup.string().defined().nullable(),
      features: yup.array().nullable().defined(),
    })
    .required();
}

export function cureCategoryDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<ICureCategoryDto> {
  return yup
    .object({
      id: yup.number().required(),
      internalName: yup.string().required(),
    })
    .required();
}

export function cureDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<ICureDto> {
  return yup
    .object({
      id: yup.number().required(),
      cureCategory: yup
        .mixed<ICureCategoryDto>(cureCategoryDtoValidation(translation))
        .required(),
      internalName: yup.string().trim().required(),
      isActive: yup.bool().required(),
      cureDurationDays: yup.number().required(),
      imageLinks: yup.array().nullable().defined(),
      gaItemCategory: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory2: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory3: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory4: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemCategory5: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),
      gaItemVariant: yup
        .string()
        .trim()
        .transform((x) => (x === '' ? null : x))
        .nullable()
        .defined(),

      cureTranslationList: yup
        .array()
        .of<ICureTranslationDto>(cureTranslationDtoValidation(translation))
        .required(),
    })
    .defined();
}

export interface IPriceRenderProps {
  price: number;
  offerPrice?: number | null;
}

export function PriceRender(props: IPriceRenderProps) {
  const { price, offerPrice } = props;

  const renderNumber = (i: number) => {
    return i.toFixed(2).replace('.', ',') + '€';
  };

  if (offerPrice === null || offerPrice === undefined) {
    return <span>{renderNumber(price)}</span>;
  } else if (offerPrice > price) {
    return <span>{renderNumber(offerPrice)}</span>;
  } else {
    return (
      <span>
        <span className="line-through">{renderNumber(price)}</span>{' '}
        {renderNumber(offerPrice)}
      </span>
    );
  }
}

export default PriceRender;

import { DateOnly } from '../utility';
import { ICureDto } from './ICureDto';
import { IPromotionDto } from './IPromotionDto';
import { IReservationCureDto } from './IReservationCureDto';
import { IReservationRoomDto } from './IReservationRoomDto';

export interface IReservationDto {
  id: number;

  from: DateOnly;
  to: DateOnly;
  personCount: number;
  roomCount: number;

  roomList: IReservationRoomDto[];
  cureList: IReservationCureDto[];

  contactMail: string;
  contactBirthday: DateOnly;
  contactPhone: string;
  contactTitle: string | null;
  contactFirstname: string;
  contactLastname: string;

  contactStreet: string;
  contactStreetNr: string;
  contactPostal: string;
  contactCity: string;
  contactCountry: string;

  contactLanguage: string | null;

  acceptedCost: number;
  acceptedDeposit: number;

  agbAccepted: boolean;
  privacyPolicyAccepted: boolean;
  newsletter: boolean;

  promotion: IPromotionDto | null;

  message: string | null;
}

export const EmptyReservationDto: IReservationDto = {
  id: 0,

  from: new DateOnly(),
  to: new DateOnly(),
  personCount: 1,
  roomCount: 1,

  roomList: [],
  cureList: [],

  contactMail: '',
  contactBirthday: new DateOnly(),
  contactPhone: '',
  contactTitle: null,
  contactFirstname: '',
  contactLastname: '',

  contactStreet: '',
  contactStreetNr: '',
  contactPostal: '',
  contactCity: '',
  contactCountry: '',

  contactLanguage: null,

  acceptedCost: 0,
  acceptedDeposit: 0,

  agbAccepted: false,
  privacyPolicyAccepted: false,
  newsletter: false,

  promotion: null,

  message: null,
};

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useState } from 'react';

export interface ITranslationDetailProps {
  text: {
    title: string;

    subtitle?: string | null;
    teaser?: string | null;
    description: string | null;
    features?: string[] | null;
  };

  imageLinks: string[] | null;
}

export function TranslationDetail(props: ITranslationDetailProps) {
  const [imgIndex, setImgIndex] = useState<number>(0);

  const onLeft = () => {
    if (!props.imageLinks) {
      return;
    }
    let next = imgIndex - 1;
    if (next < 0) {
      next = props.imageLinks.length - 1;
    }
    setImgIndex(next);
  };

  const onRight = () => {
    if (!props.imageLinks) {
      return;
    }
    let next = imgIndex + 1;
    if (next >= props.imageLinks.length) {
      next = 0;
    }
    setImgIndex(next);
  };

  return (
    <div className="space-y-8 w-full">
      {props.imageLinks && props.imageLinks.length > 0 && (
        <div className="relative h-48 sm:h-64">
          <button
            type="button"
            onClick={() => onLeft()}
            className={classNames(
              props.imageLinks.length > 1 ? 'flex' : 'hidden',
              'absolute p-2 left-0 h-full items-center justify-center hover:backdrop-blur hover:text-white'
            )}
          >
            <ArrowLeftCircleIcon className="w-6 h-6"></ArrowLeftCircleIcon>
          </button>

          <button
            type="button"
            onClick={() => onRight()}
            className={classNames(
              props.imageLinks.length > 1 ? 'flex' : 'hidden',
              'absolute p-2 right-0 h-full items-center justify-center hover:backdrop-blur hover:text-white'
            )}
          >
            <ArrowRightCircleIcon className="w-6 h-6"></ArrowRightCircleIcon>
          </button>

          <img
            src={props.imageLinks[imgIndex]}
            alt={props.imageLinks[imgIndex]}
            className="h-48 sm:h-64 w-full object-cover"
          ></img>
        </div>
      )}

      <header className="mx-8 space-y-6">
        <h1 className="text-4xl font-bold uppercase text-[#00604F]">
          {props.text.title}
        </h1>

        {props.text.subtitle && (
          <p className="text-2xl uppercase text-[#00604F]">
            {props.text.subtitle}
          </p>
        )}
      </header>

      <main className="mx-8 space-y-4">
        {props.text.description && (
          <p className="whitespace-pre-line">{props.text.description}</p>
        )}

        {props.text.features && (
          <div className="space-y-2 mt-2">
            Features:
            <ul className="list list-inside list-disc">
              {props.text.features?.map((f, idx) => (
                <li key={idx}>{f}</li>
              ))}
            </ul>
          </div>
        )}
      </main>
    </div>
  );
}

export default TranslationDetail;

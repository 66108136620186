export interface IFormGroupProps {
  title: string;
  description?: string | undefined;
  isOptional?: boolean | undefined;
  addColon?: boolean | undefined;

  children: React.ReactNode | React.ReactNode[] | string;
}

export function FormGroup(props: IFormGroupProps) {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {props.title}
          {props.addColon === true ? ':' : ''}
        </h2>

        {props.isOptional && (
          <span className="text-gray-600 text-sm">(optional)</span>
        )}

        {props.description && (
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {props.description}
          </p>
        )}
      </div>

      <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 md:col-span-2 w-full">
        {props.children}
      </div>
    </div>
  );
}

export default FormGroup;

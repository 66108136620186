import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  EmptyVoucherDto,
  IVoucherDto,
  IVoucherResponseDto,
  voucherDtoValidation,
} from '../model';
import { DefaultGaItemDto } from '../model/analytics';
import { getGaUserIdFromEmail, gtmEvent } from '../utility';
import { useTranslation } from 'react-i18next';
import { FormGroup, PromotionInput, StyledButton, StyledInput } from '../ui';
import { useEffect, useState } from 'react';
import { INotificationService, IPromotionApi } from '../services';
import { IGaItemDto, IGaPurchaseEventDto } from '../model/analytics';
import { IVoucherApi } from '../services/voucherApi';
import PaymentPicker from './payment-picker';
import classNames from 'classnames';
import { IPromotionDto, PromotionType } from '../model/IPromotionDto';
import { CheckIcon } from '@heroicons/react/20/solid';

export interface IFormProps {
  voucherApi: IVoucherApi;
  promotionApi: IPromotionApi;
  notificationService: INotificationService;
  onDraftClick?: (data: Partial<IVoucherDto>) => Promise<void>;
}

export function Form(props: IFormProps) {
  const { t, i18n } = useTranslation();

  const methods = useForm<IVoucherDto>({
    resolver: yupResolver(voucherDtoValidation(t)),
    defaultValues: { ...EmptyVoucherDto, contactLanguage: i18n.language },
    mode: 'onChange',
  });

  const watch = methods.watch();

  const [isDraftValid, setIsDraftValid] = useState<boolean>(false);
  const [promotion, setPromotion] = useState<IPromotionDto>();

  useEffect(() => {
    setIsDraftValid(watch.value > 0);
  }, [watch]);

  const [response, setResponse] = useState<IVoucherResponseDto>();

  if (response) {
    return (
      <div className="mt-4 mb-10">
        <PaymentPicker response={response}></PaymentPicker>
      </div>
    );
  }

  const onSave = async (dto: IVoucherDto): Promise<void> => {
    try {
      const result = await props.voucherApi.addVoucher(dto);

      //TRACK
      {
        const items: IGaItemDto[] = [
          {
            ...DefaultGaItemDto,
            item_id: `voucher`,
            item_name: `voucher`,
            price: dto.value,
            quantity: 1,
          },
        ];

        const payload: IGaPurchaseEventDto = {
          currency: 'EUR',
          value: dto.value,
          transaction_id: `voucher_${result.voucherId ?? 'unkown'}`,
          items: items,
        };

        gtmEvent(
          'purchase',
          await getGaUserIdFromEmail(dto.contactMail),
          payload
        );
      }

      setResponse(result);
    } catch {
      props.notificationService.createNotification({
        title: 'ERROR',
        type: 'error',
        description: 'CALL REZEPTION',
        hideAfterSeconds: 10,
      });
    }
  };

  const loadPromotionByCode = (code: string) => {
    if (!code) {
      setPromotion(undefined);
      methods.setValue('promotion', null);
      return;
    }

    props.promotionApi
      .findVoucher(code)
      .then((e) => {
        if (e !== null) {
          if (e.typeVoucher === null || e.valueVoucher === null) {
            props.notificationService.createNotification({
              type: 'failure',
              title: 'Code nicht bei Gutscheine gültig',
              hideAfterSeconds: 10,
            });
          } else {
            setPromotion(e);
            methods.setValue('promotion', e);
          }
        } else {
          props.notificationService.createNotification({
            type: 'failure',
            title: t('reservation.promo.failed'),
            hideAfterSeconds: 10,
          });
        }
      })
      .catch(() => {
        props.notificationService.createNotification({
          type: 'error',
          title: t('reservation.promo.failed'),
          hideAfterSeconds: 10,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <div className="mt-4 mb-10">
        <form
          onSubmit={methods.handleSubmit((data) => {
            return onSave(data).then(() => methods.reset(data));
          })}
          className="space-y-2"
        >
          <FormGroup title={t('voucher.data.title')} addColon={true}>
            <div className="sm:col-span-6">
              <Controller
                name="value"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    inputType: 'number',
                    numberStepSize: '1',
                    numberMin: '50',
                    prefix: '€',
                    title: t('voucher.data.voucher-value'),
                  })
                }
              />
            </div>

            <div className="sm:col-span-6 space">
              <PromotionInput
                title={t('voucher.promo.code')}
                onChange={(data) => loadPromotionByCode(data)}
              ></PromotionInput>

              {promotion && (
                <div className="space-y-2 m-2 flex gap-2 items-center">
                  <CheckIcon className="size-5"></CheckIcon>
                  {promotion.code} -{promotion.valueVoucher}
                  {promotion.typeVoucher === PromotionType.fixedAll ? '€' : '%'}
                </div>
              )}
            </div>

            <div className="sm:col-span-6">
              <Controller
                name="title"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('voucher.data.voucher-title'),
                  })
                }
              />
            </div>

            <div className="sm:col-span-6">
              <Controller
                name="text"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('voucher.data.voucher-text'),
                  })
                }
              />
            </div>

            {props.onDraftClick !== undefined && (
              <div className="sm:col-span-6">
                <StyledButton
                  type="button"
                  disabled={!isDraftValid}
                  onClickAsync={() =>
                    props.onDraftClick!({
                      ...methods.getValues(),
                    })
                  }
                >
                  {t('voucher.preview')}
                </StyledButton>
              </div>
            )}
          </FormGroup>

          <FormGroup title={t('contact.title')} addColon={true}>
            <div className="sm:col-span-3">
              <Controller
                name="contactMail"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('contact.mail'),
                    prefix: '✉',
                    inputType: 'email',
                  })
                }
              />
            </div>

            <div className="sm:col-span-3">
              <Controller
                name="contactPhone"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('contact.call'),
                    prefix: '📞',
                    placeholder: '+43',
                  })
                }
              />
            </div>
          </FormGroup>

          <FormGroup title={t('voucher.contact.title')} addColon={true}>
            <div className="sm:col-span-6 grid sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Controller
                  name="contactTitle"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.salutation'),
                      hint: '(optional)',
                    })
                  }
                />
              </div>
            </div>

            <div className="sm:col-span-3 lg:col-span-2">
              <Controller
                name="contactFirstname"
                control={methods.control}
                render={(s) =>
                  StyledInput({ ...s, title: t('personal.firstname') })
                }
              />
            </div>

            <div className="sm:col-span-3 lg:col-span-2">
              <Controller
                name="contactLastname"
                control={methods.control}
                render={(s) =>
                  StyledInput({ ...s, title: t('personal.lastname') })
                }
              />
            </div>

            <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
              <div className="sm:col-span-3 lg:col-span-2">
                <Controller
                  name="contactStreet"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.street'),
                    })
                  }
                />
              </div>

              <div className="sm:col-span-2 lg:col-span-1">
                <Controller
                  name="contactStreetNr"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.number'),
                    })
                  }
                />
              </div>
            </div>

            <div className="sm:col-span-6 grid sm:grid-cols-6 gap-x-6 gap-y-8">
              <div className="sm:col-span-2 lg:col-span-1">
                <Controller
                  name="contactPostal"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.postal'),
                    })
                  }
                />
              </div>

              <div className="sm:col-span-3 lg:col-span-2">
                <Controller
                  name="contactCity"
                  control={methods.control}
                  render={(s) =>
                    StyledInput({
                      ...s,
                      title: t('personal.city'),
                    })
                  }
                />
              </div>
            </div>

            <div className="sm:col-span-4 lg:col-span-3">
              <Controller
                name="contactCountry"
                control={methods.control}
                render={(s) =>
                  StyledInput({
                    ...s,
                    title: t('personal.country'),
                  })
                }
              />
            </div>

            <div className="sm:col-span-6 ">
              <StyledButton
                className={classNames(
                  'w-64 h-12 place-items-center',
                  methods.formState.isValid &&
                    'outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline'
                )}
                design="primary"
                type="submit"
                disabled={
                  methods.formState.isSubmitting ||
                  methods.formState.isLoading ||
                  !methods.formState.isValid
                }
              >
                <span className="w-full">{t('voucher.action')}</span>
              </StyledButton>
            </div>
          </FormGroup>
        </form>
      </div>
    </FormProvider>
  );
}

export default Form;

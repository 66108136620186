import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import {
  ICureDto,
  IReservationCureDto,
  ISeasonCurePriceDto,
} from '../../model';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { MinusIcon, PlusIcon, UserIcon } from '@heroicons/react/20/solid';
import { gtmEvent, removeFirstOccurrenceByProperty } from '../../utility';
import {
  IStepperContext,
  ITranslationDetailProps,
  StepperContext,
  StyledButton,
  StyledDialog,
  TranslationDetail,
} from '../../ui';
import classNames from 'classnames';
import { IGaCartEventDto } from '../../model/analytics';
import { MapCureToAnalyticsItem } from '../../mapping';
import { useReadLocalStorage } from 'usehooks-ts';

export interface ICurePicker {
  cures: ISeasonCurePriceDto[];
}

export function CurePicker(props: ICurePicker) {
  const { t, i18n } = useTranslation();
  const methods = useFormContext();
  const stepperContext = useContext<IStepperContext>(StepperContext);
  const [modalContent, setModalContent] = useState<ITranslationDetailProps>();

  const version = useReadLocalStorage<number>('reservation.a-b');

  const personWatch: number = methods.watch('personCount');
  const cureWatch: IReservationCureDto[] = methods.watch('cureList');

  const trackAddCure = (dto: ICureDto) => {
    const currentCures = cureWatch;

    const foundPrice = props.cures.find((x) => x.cure.id === dto.id);
    if (currentCures.length < personWatch && foundPrice) {
      const items = [MapCureToAnalyticsItem(foundPrice)];

      const eventProps: IGaCartEventDto = {
        currency: 'EUR',
        value:
          items
            .map((e) => e.price)
            .filter((e) => e)
            .reduce((v, c) => v! + c!, 0.0) ?? 0,
        items: items,
      };

      gtmEvent('add_to_cart', undefined, eventProps);
    }
  };

  const trackRemoveCure = (dto: ICureDto) => {
    if (cureWatch.find((x) => x.cure.id === dto.id) === undefined) {
      return;
    }

    const foundPrice = props.cures.find((x) => x.cure.id === dto.id);

    if (dto && foundPrice) {
      const items = [MapCureToAnalyticsItem(foundPrice)];

      const eventProps: IGaCartEventDto = {
        currency: 'EUR',
        value:
          items
            .map((e) => e.price)
            .filter((e) => e)
            .reduce((v, c) => v! + c!, 0.0) ?? 0,
        items: items,
      };

      gtmEvent('remove_from_cart', undefined, eventProps);
    }
  };

  const setCure = (dto: ICureDto) => {
    if (cureWatch.length > 0) {
      trackRemoveCure(cureWatch[0].cure);
    }

    trackAddCure(dto);
    methods.setValue('cureList', [{ id: 0, cure: dto }]);
  };

  const addCure = (dto: ICureDto) => {
    const currentCures = cureWatch;

    if (currentCures.length < personWatch) {
      trackAddCure(dto);
      methods.setValue('cureList', [...cureWatch, { id: 0, cure: dto }]);
    }
  };

  const removeCure = (dto: ICureDto) => {
    let currentCures = [...cureWatch];

    trackRemoveCure(dto);

    currentCures = removeFirstOccurrenceByProperty(
      currentCures,
      (e) => e.cure.id === dto.id
    );

    methods.setValue('cureList', currentCures);
  };

  const cannotAddCures = () => cureWatch.length >= personWatch;
  const countCures = (dto: ICureDto) =>
    cureWatch.filter((e) => e.cure.id === dto.id).length;

  const onNext = () => {
    if (stepperContext.onNextStep) {
      stepperContext.onNextStep();
    }
  };

  return (
    <div className="space-y-2">
      <StyledDialog
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent && (
          <TranslationDetail
            text={modalContent.text}
            imageLinks={modalContent.imageLinks}
          ></TranslationDetail>
        )}
      </StyledDialog>

      {version === 1 && (
        <>
          <h2 className="text-[#00604F] font-bold text-lg">
            {t('reservation.cure.title')}
          </h2>
          <p className="font-bold">{t('reservation.cure.description')}</p>

          <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:gap-x-6 lg:gap-8">
            {props.cures
              .map((season) => {
                return {
                  ...season,
                  translation: season.cure.cureTranslationList.find(
                    (f) => f.langCode === i18n.language
                  ),
                };
              })
              .map((season) => (
                <div
                  key={season.cure.id}
                  className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg relative h-96"
                >
                  {season.cure.imageLinks ? (
                    <img
                      src={season.cure.imageLinks[0]}
                      alt={season.translation?.title}
                      className="absolute h-full object-cover object-center group-hover:opacity-75"
                    />
                  ) : (
                    <div className="bg-primary-700 h-full w-full absolute">
                      <img
                        src="https://cdn.mandira-ayurveda.at/images/claim/2024/white_twoline.svg"
                        alt="Mandira-Claim."
                        className="h-full w-full p-6 object-fill object-center group-hover:opacity-75"
                      />
                    </div>
                  )}

                  <div
                    aria-hidden="true"
                    className="absolute h-full w-full bg-gradient-to-b from-transparent to-black opacity-50"
                  />

                  <div className="absolute py-2 px-1 top-0 flex justify-between items-center w-full text-white">
                    <div
                      className={classNames(
                        'px-2 py-1',
                        countCures(season.cure) > 0 &&
                          'rounded-md backdrop-blur'
                      )}
                    >
                      {personWatch > 1 && countCures(season.cure) > 0 && (
                        <div className="text-2xl">
                          {countCures(season.cure)}x
                        </div>
                      )}

                      {personWatch === 1 && countCures(season.cure) > 0 && (
                        <div className="text-2xl">
                          <CheckCircleIcon className="w-7 h-7"></CheckCircleIcon>
                        </div>
                      )}

                      {countCures(season.cure) === 0 && <div></div>}
                    </div>

                    <div className="px-2 py-1 rounded backdrop-blur">
                      <p className="text-lg">
                        <UserIcon className="w-4 h-4 inline"></UserIcon>
                        {': '}
                        {season.priceAdult.toFixed(2)}€
                      </p>
                    </div>
                  </div>

                  <div className="absolute flex items-end h-full w-full">
                    <div className="flex flex-col w-full backdrop-blur">
                      <button
                        className="text-left"
                        type="button"
                        onClick={() =>
                          season.translation &&
                          setModalContent({
                            text: season.translation,
                            imageLinks: season.cure.imageLinks,
                          })
                        }
                      >
                        <div className="text-white px-4 py-2">
                          <h2 className="text-lg">
                            {season.translation?.title}
                          </h2>
                          <p className="text-sm">
                            {season.translation?.teaser}
                          </p>
                          <p className="underline mt-1">
                            {t('reservation.more')}
                          </p>
                        </div>
                      </button>

                      {personWatch === 1 && (
                        <button
                          type="button"
                          className="p-4 hover:bg-primary-600 hover:bg-opacity-50 hover:text-white outline-1 outline outline-white text-white"
                          onClick={() => {
                            setCure(season.cure);
                          }}
                        >
                          {t('reservation.cure.select')}
                        </button>
                      )}

                      {personWatch > 1 && (
                        <div className="w-full grid grid-cols-2">
                          <button
                            type="button"
                            className="p-4 text-lg hover:bg-primary-600 hover:bg-opacity-50 hover:text-white outline-1 outline outline-white text-white"
                            disabled={cannotAddCures()}
                            onClick={() => addCure(season.cure)}
                          >
                            +
                          </button>

                          <button
                            type="button"
                            className="p-4 text-lg hover:bg-red-500 hover:bg-opacity-50 hover:text-white outline-1 outline outline-white text-white"
                            disabled={
                              cureWatch.findIndex(
                                (e) => e.cure.id === season.cure.id
                              ) === -1
                            }
                            onClick={() => removeCure(season.cure)}
                          >
                            -
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}

      {version === 0 && (
        <div className="space-y-4 max-w-3xl mx-auto">
          <h2 className="text-[#00604F] font-bold text-lg">
            {t('reservation.cure.title')}
          </h2>
          <p className="font-bold">{t('reservation.cure.description')}</p>

          <div className="mt-6 flex flex-col divide-y divide-black">
            {props.cures
              .map((season) => {
                return {
                  ...season,
                  translation: season.cure.cureTranslationList.find(
                    (f) => f.langCode === i18n.language
                  ),
                };
              })
              .map((season) => (
                <div
                  key={season.cure.id}
                  className="group px-2 sm:px-0 py-4 bg-white flex flex-col md:flex-row gap-3 items-center w-full"
                >
                  <div className="flex gap-4 flex-row items-center h-full w-full">
                    <button
                      className="text-left flex-1"
                      type="button"
                      onClick={() =>
                        season.translation &&
                        setModalContent({
                          text: season.translation,
                          imageLinks: season.cure.imageLinks,
                        })
                      }
                    >
                      <h2 className="text-xl font-bold uppercase text-[#00604F]">
                        {season.translation?.title}
                      </h2>
                      <p className="text-sm">{season.translation?.teaser}</p>
                      <p className="underline mt-1">{t('reservation.more')}</p>
                    </button>

                    <div className="flex-shrink-0">
                      <p className="text-lg">
                        <UserIcon className="w-4 h-4 inline"></UserIcon>
                        {': '}
                        {season.priceAdult.toFixed(2)}€
                      </p>
                    </div>
                  </div>

                  <div className="shrink-0 flex items-center gap-4 justify-evenly">
                    <button
                      type="button"
                      className="m-2 p-3 bg-white text-black border-2 border-primary-800 hover:outline outline-2 outline-primary-800 outline-offset-2"
                      disabled={
                        cureWatch.findIndex(
                          (e) => e.cure.id === season.cure.id
                        ) === -1
                      }
                      onClick={() => removeCure(season.cure)}
                    >
                      <MinusIcon className="w-4 h-4"></MinusIcon>
                    </button>

                    <div className="text-xl min-w-4">
                      {countCures(season.cure)}
                    </div>

                    <button
                      type="button"
                      className="m-2 p-3 bg-primary-800 text-white border-2 border-primary-800 hover:outline outline-2 outline-primary-800 outline-offset-2"
                      disabled={cannotAddCures()}
                      onClick={() => addCure(season.cure)}
                    >
                      <PlusIcon className="w-4 h-4"></PlusIcon>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-3 md:flex items-center justify-center gap-2 bottom-0 right-0 p-4 fixed bg-white w-full">
        <StyledButton
          type="button"
          className="w-full md:w-48 h-12 md:h-auto"
          onClick={() =>
            stepperContext.onLastStep && stepperContext.onLastStep()
          }
        >
          <span className="flex justify-center items-center w-full h-full">
            {t('reservation.back')}
          </span>
        </StyledButton>

        <StyledButton
          type="button"
          design="primary"
          rootClassName="w-full col-span-2 md:w-48 md:w-48 h-12 md:h-auto"
          className="w-full outline-2 outline outline-primary-500 outline-offset-2 animate-pulse-outline"
          disabled={personWatch !== cureWatch.length}
          onClick={() => onNext()}
        >
          <span className="flex justify-center items-center w-full h-full">
            {t('reservation.forward')}
          </span>
        </StyledButton>
      </div>
    </div>
  );
}

export default CurePicker;

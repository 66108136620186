import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { EmptyEnquiry, IEnquiryDto, IEnquiryOptionDto } from '../model';
import { enquiryDtoValidation } from '../model';
import { useTranslation } from 'react-i18next';
import { FormGroup, StyledInput, StyledTextarea } from '../ui';
import classNames from 'classnames';
import { IEnquiryState } from './state';
import SubmitForm from './submit-form';
import { DateOnly } from '../utility';
import { addDays, nextFriday, startOfDay } from 'date-fns';

export interface IFormProps {
  enquiryState: IEnquiryState;
  treatmentOptions: IEnquiryOptionDto[];

  onSubmit?: (data: IEnquiryDto) => Promise<unknown>;
}

export function Form(props: IFormProps) {
  const { t, i18n } = useTranslation();

  const { control, handleSubmit, reset, formState } = useForm<IEnquiryDto>({
    resolver: yupResolver(enquiryDtoValidation(t)),
    defaultValues: {
      ...EmptyEnquiry,
      from: new DateOnly(nextFriday(startOfDay(new Date()))),
      to: new DateOnly(addDays(nextFriday(startOfDay(new Date())), 10)),
      personCount: 1,
      contactLanguage: i18n.language,
      roomOptions: [],
      treatmentOptions: props.treatmentOptions,
    },
    mode: 'onChange',
  });

  const treatments = useFieldArray({ control, name: 'treatmentOptions' });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (props.onSubmit) {
          return props.onSubmit(data).then(() => reset(data));
        }
      })}
    >
      <div className="flex flex-col gap-3 mt-4 mb-28">
        <FormGroup title={t('traveldata.title')} addColon={true}>
          <div className="sm:col-span-3">
            <Controller
              name="from"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('traveldata.from'),
                  inputType: 'date',
                })
              }
            />
          </div>

          <div className="sm:col-span-3">
            <Controller
              name="to"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('traveldata.to'),
                  inputType: 'date',
                })
              }
            />
          </div>

          <div className="sm:col-span-4 lg:col-span-3">
            <Controller
              name="personCount"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  numberMax: '50',
                  numberMin: '1',
                  numberStepSize: '1',
                  title: t('traveldata.person-count'),
                  inputType: 'number',
                })
              }
            />
          </div>
        </FormGroup>

        <FormGroup title={t('contact.title')} addColon={true}>
          <div className="sm:col-span-3">
            <Controller
              name="contactMail"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('contact.mail'),
                  prefix: '✉',
                  inputType: 'email',
                  hint: '*',
                })
              }
            />
          </div>

          <div className="sm:col-span-3">
            <Controller
              name="contactPhone"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('contact.call'),
                  prefix: '📞',
                  placeholder: '+43',
                  hint: '(optional)',
                })
              }
            />
          </div>
        </FormGroup>

        <FormGroup
          title={t('personal.title')}
          addColon={true}
          isOptional={true}
        >
          <div className="sm:col-span-3 lg:col-span-2">
            <Controller
              name="contactFirstname"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.firstname'),
                  hint: '(optional)',
                })
              }
            />
          </div>

          <div className="sm:col-span-3 lg:col-span-2">
            <Controller
              name="contactLastname"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('personal.lastname'),
                  hint: '(optional)',
                })
              }
            />
          </div>
        </FormGroup>

        <FormGroup
          title={t('treatment.title')}
          addColon={true}
          isOptional={props.treatmentOptions.length > 1}
        >
          <ul className="flex flex-col gap-2 sm:col-span-6">
            {treatments.fields.map((option, idx) => (
              <li key={option.id}>
                <Controller
                  render={({ field }) => (
                    <button
                      type="button"
                      disabled={props.treatmentOptions.length === 1}
                      className={classNames(
                        'rounded-md border p-2 w-full text-left flex gap-2',
                        field.value &&
                          'text-green-800 border-green-800 bg-green-50',
                        !field.value &&
                          'text-gray-900 border-gray-300 bg-gray-50'
                      )}
                      onClick={() => field.onChange(!field.value)}
                    >
                      {field.value && ' ☑  '}
                      {!field.value && ' □  '}
                      {option.name}
                    </button>
                  )}
                  name={`treatmentOptions.${idx}.valueEnabled`}
                  control={control}
                />
              </li>
            ))}
          </ul>
        </FormGroup>

        <FormGroup
          title={t('consultation.message.title')}
          isOptional={true}
          description={t('consultation.message.description')}
        >
          <div className="sm:col-span-6">
            <Controller
              name="message"
              control={control}
              render={(s) => StyledTextarea({ ...s, title: '' })}
            />
          </div>
        </FormGroup>

        <FormGroup
          title={t('callback.title')}
          description={t('callback.description')}
          addColon={true}
          isOptional={true}
        >
          <div className="sm:col-span-3 lg:col-span-2">
            <Controller
              name="callback"
              control={control}
              render={(s) =>
                StyledInput({
                  ...s,
                  title: t('callback.date'),
                  inputType: 'datetime-local',
                  numberStepSize: '900',
                })
              }
            />

            <p className="mt-3">{t('callback.time')}</p>
          </div>
        </FormGroup>

        <FormGroup title={t('newsletter.title')}>
          <div className="sm:col-span-6">
            <Controller
              name="newsletter"
              control={control}
              render={(s) => (
                <button
                  type="button"
                  className={classNames(
                    'rounded-md border p-2 w-full text-left flex gap-2',
                    s.field.value &&
                      'text-green-800 border-green-800 bg-green-50',
                    !s.field.value && 'text-gray-900 border-gray-300 bg-gray-50'
                  )}
                  onClick={() => s.field.onChange(!s.field.value)}
                >
                  {s.field.value ? ' ☑  ' : ' □  '}
                  {t('newsletter.message')}
                </button>
              )}
            />
          </div>
        </FormGroup>
      </div>

      <SubmitForm
        formState={formState}
        enquiryState={props.enquiryState}
      ></SubmitForm>
    </form>
  );
}

export default Form;

import classNames from 'classnames';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export function StyledTextarea<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(args: {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;

  //optional
  title?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  textAlign?: 'left' | 'right' | 'center';
  disabled?: boolean;
}) {
  const { field, fieldState } = args;

  const showError = fieldState.invalid;

  const getDefaultValue = () => {
    return field.value;
  };

  return (
    <div>
      {args?.title && (
        <label
          className="block  font-medium text-gray-700"
          htmlFor={field.name}
        >
          {args?.title}
        </label>
      )}

      <div className="flex rounded-md relative">
        {args?.prefix !== undefined && (
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {args?.prefix}
          </span>
        )}

        <div className={'relative block w-full'}>
          <textarea
            id={field.name}
            className={classNames(
              showError &&
                'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
              'w-full min-h-48 min-w-0 flex-1 border-gray-300 focus:border-primary-800 focus:ring-primary-800 sm:text-sm',
              args?.suffix === undefined && 'rounded-r-md',
              args?.prefix === undefined && 'rounded-l-md',
              args?.textAlign === 'right' && 'text-right',
              args?.textAlign === 'center' && 'text-center',
              args?.textAlign === 'left' && 'text-left'
            )}
            defaultValue={getDefaultValue()}
            name={field.name}
            disabled={args?.disabled}
            onChange={(event) => field.onChange(event)}
          />

          {showError && (
            <div
              className={classNames(
                'pointer-events-none absolute inset-y-0 flex items-center ',
                args?.textAlign === 'right' && 'left-0 pl-3',
                args?.textAlign !== 'right' && 'right-0 pr-3'
              )}
            >
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        {args?.suffix !== undefined && (
          <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {args?.suffix}
          </span>
        )}
      </div>

      {showError && (
        <p className="mt-2 text-sm text-red-600">{fieldState.error?.message}</p>
      )}
    </div>
  );
}

export default StyledTextarea;

import { useEffect, useState } from 'react';
import { combineLatest, from } from 'rxjs';
import { distinctBy } from '../../utility';
import { useTranslation } from 'react-i18next';
import {
  ITranslationDetailProps,
  PriceRender,
  StyledDialog,
  TranslationDetail,
} from '../../ui';
import { IProductApi, ProductApi } from '../../services/productApi';
import { IProductDto } from '../../model/IProductDto';
import { IOfferProductDto } from '../../model/IOfferProductDto';

export interface IOfferProductDisplayProps {
  offerProducts: IOfferProductDto[];
}

export function OfferProductDisplay(props: IOfferProductDisplayProps) {
  const { t, i18n } = useTranslation('offer-package');
  const api: IProductApi = new ProductApi();
  const { offerProducts } = props;

  const [products, setProducts] = useState<IProductDto[]>();
  const [modalContent, setModalContent] = useState<ITranslationDetailProps>();

  useEffect(() => {
    const idsToLoad = [...distinctBy(offerProducts, (e) => e.productId)]
      .map((e) => e.productId)
      .map((e) => from(api.getById(e)));

    combineLatest(idsToLoad).subscribe({
      next: (data) => setProducts(data),
    });
  }, [offerProducts]);

  if (!products) {
    return <p>Loading...</p>;
  }

  const renderProduct = (id: number) => {
    const product = products?.find((e) => e.id === id);
    if (!product) {
      return '';
    }

    const translation = product?.productTranslationList.find(
      (e) => e.langCode === i18n.language
    );

    return translation?.title;
  };

  return (
    <div>
      <StyledDialog
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent && (
          <TranslationDetail
            text={modalContent.text}
            imageLinks={modalContent.imageLinks}
          ></TranslationDetail>
        )}
      </StyledDialog>

      <h3 className="text-lg font-bold">{t('product.title')}</h3>

      <ul className="space-y-2">
        {offerProducts.map((item) => (
          <li key={item.id}>
            <button
              type="button"
              className="flex group justify-between items-center w-full gap-2 bg-slate-50 hover:bg-slate-100 hover:shadow rounded p-2 print:p-0"
              onClick={() => {
                const product = products?.find((e) => e.id === item.productId);
                const translation = product?.productTranslationList.find(
                  (e) => e.langCode === i18n.language
                );

                if (translation) {
                  setModalContent({
                    text: translation,
                    imageLinks: product?.imageLinks ?? [],
                  });
                }
              }}
            >
              <div className="text-left">
                <p className="font-bold">
                  {item.amount}x {renderProduct(item.productId)}
                </p>
                <p className="group-hover:underline">{t('shared.more')}...</p>
              </div>

              <div className="text-right">
                <PriceRender
                  price={item.price}
                  offerPrice={item.offerPrice}
                ></PriceRender>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OfferProductDisplay;

export function RoomCalc(personCount: number, roomCount: number): number[] {
  if (personCount === roomCount) {
    return Array.from({ length: roomCount }, () => 1);
  } else if (personCount === roomCount * 2) {
    return Array.from({ length: roomCount }, () => 2);
  }

  let result: number[] = [];

  let remainingPersons = personCount;
  let remainingRooms = roomCount;

  //add all double rooms
  while (remainingPersons !== remainingRooms) {
    remainingPersons -= 2;
    remainingRooms -= 1;

    result.push(2);
  }

  result = [...result, ...Array.from({ length: remainingRooms }, () => 1)];

  return result;
}

import { IApiSieveDto, IPaginationResponseDto } from '../model';
import { axiosMod } from '../utility';
import { ICrudApiCaller } from './ICrudApiCaller';

export abstract class BaseCrudApiCaller<TDto extends { id: TKey | null }, TKey>
  implements ICrudApiCaller<TDto, TKey>
{
  protected endpointUrl: string;

  constructor(baseUrl: string) {
    this.endpointUrl = baseUrl;
  }

  async add(item: TDto): Promise<TDto> {
    const response = await axiosMod().post<TDto>(this.endpointUrl, item);

    return response.data;
  }

  getAll(): Promise<IPaginationResponseDto<TDto>>;
  getAll(sieve: IApiSieveDto): Promise<IPaginationResponseDto<TDto>>;
  async getAll(sieve?: IApiSieveDto): Promise<IPaginationResponseDto<TDto>> {
    const response = await axiosMod().get<IPaginationResponseDto<TDto>>(
      this.endpointUrl,
      {
        params: {
          filters: sieve?.filters,
          sorts: sieve?.sorts,
          page: sieve?.page,
          pageSize: sieve?.pageSize,
        },
      }
    );

    return response.data;
  }

  async getById(id: TKey): Promise<TDto> {
    const response = await axiosMod().get<TDto>(`${this.endpointUrl}/${id}`);

    return response.data;
  }

  async update(id: TKey, item: TDto): Promise<void> {
    await axiosMod().put<TDto>(`${this.endpointUrl}/${id}`, item);
  }

  async remove(id: TKey): Promise<void> {
    await axiosMod().delete<void>(`${this.endpointUrl}/${id}`);
  }
}

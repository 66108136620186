import { useTranslation } from 'react-i18next';
import { StyledLink } from '../../ui';
import { IReservationResponseDto } from '../../model';
import { useEffect } from 'react';

export interface IPaymentPickerProps {
  response: IReservationResponseDto;
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function PaymentPicker(props: IPaymentPickerProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.response.checkoutUrl) {
      delayedOnNav(props.response.checkoutUrl);
    }
  }, [props.response.checkoutUrl]);

  const delayedOnNav = async (link: string): Promise<void> => {
    try {
      await delay(2000);

      if (typeof window !== 'undefined' && link) {
        window.location.href = link;
      }
    } catch {
      console.log('could not automatically navigate');
    }
  };

  return (
    <div className="flex justify-center">
      <div className="space-y-2 max-w-lg w-full">
        {(props.response.hasError || props.response.hasErrorPayment) && (
          <h1 className="text-lg text-slate-800">
            {t('reservation.finish.api-error')}
          </h1>
        )}

        {props.response.checkoutUrl ? (
          <div className="space-y-2">
            <h1 className="text-lg text-slate-800">
              {t('reservation.finish.pay-deposit-title')}
            </h1>
            <h2 className="text-slate-700">
              {t('reservation.finish.pay-deposit-description')}
            </h2>

            <StyledLink design="primary" href={props.response.checkoutUrl}>
              {t('reservation.finish.pay-deposit')}
            </StyledLink>
          </div>
        ) : (
          <div className="space-y-2">
            <h1 className="text-lg font-bold text-slate-800">
              {t('reservation.finish.success')}
            </h1>

            <StyledLink href="https://www.mandira-ayurveda.at">
              {t('reservation.finish.action')}
            </StyledLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentPicker;

export function removeFirstOccurrenceByProperty<T>(
  array: T[],
  predicate: (item: T) => boolean
): T[] {
  const index = array.findIndex(predicate);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function sum<T>(array: T[], valueSelector: (item: T) => number): number {
  let sum = 0;
  array.forEach((item) => {
    const value = valueSelector(item);
    sum = sum + value;
  });

  return sum;
}

export function avg<T>(array: T[], valueSelector: (item: T) => number): number {
  let sum = 0;
  let ct = 0;

  array.forEach((item) => {
    const value = valueSelector(item);
    sum = sum + value;
    ct++;
  });

  return sum / ct;
}

export function distinctBy<T, K>(array: T[], keySelector: (item: T) => K): T[] {
  const distinctKeys = new Set<K>();
  const result: T[] = [];

  for (const item of array) {
    const key = keySelector(item);
    if (!distinctKeys.has(key)) {
      distinctKeys.add(key);
      result.push(item);
    }
  }

  return result;
}

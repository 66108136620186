import { useEffect, useState } from 'react';
import { combineLatest, from } from 'rxjs';
import { distinctBy } from '../../utility';
import { useTranslation } from 'react-i18next';
import {
  ITranslationDetailProps,
  StyledDialog,
  TranslationDetail,
} from '../../ui';
import { IOfferBoardDto } from '../..//model/IOfferBoardDto';
import { BoardApi, IBoardApi } from '../../services';
import { IBoardDto } from '../../model/IBoardDto';

export interface IOfferBoardDisplayProps {
  offerBoards: IOfferBoardDto[];
}

export function OfferBoardDisplay(props: IOfferBoardDisplayProps) {
  const { t, i18n } = useTranslation('offer-package');
  const api: IBoardApi = new BoardApi();
  const { offerBoards } = props;

  const [boards, setBoards] = useState<IBoardDto[]>();
  const [modalContent, setModalContent] = useState<ITranslationDetailProps>();

  useEffect(() => {
    const idsToLoad = [...distinctBy(offerBoards, (e) => e.boardId)]
      .map((e) => e.boardId)
      .map((e) => from(api.getById(e)));

    combineLatest(idsToLoad).subscribe({
      next: (data) => setBoards(data),
    });
  }, [offerBoards]);

  if (!boards) {
    return <p>Loading...</p>;
  }

  const renderBoard = (id: number) => {
    const board = boards?.find((e) => e.id === id);
    if (!board) {
      return '';
    }

    const translation = board?.boardTranslationList.find(
      (e) => e.langCode === i18n.language
    );

    return translation?.title;
  };

  return (
    <div>
      <StyledDialog
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent && (
          <TranslationDetail
            text={modalContent.text}
            imageLinks={modalContent.imageLinks}
          ></TranslationDetail>
        )}
      </StyledDialog>

      <h3 className="text-lg font-bold">{t('board.title')}</h3>

      <ul className="space-y-2">
        {offerBoards.map((item) => (
          <li key={item.id}>
            <button
              type="button"
              className="flex group justify-between items-center w-full gap-2 bg-slate-50 hover:bg-slate-100 hover:shadow rounded p-2 print:p-0"
              onClick={() => {
                const board = boards?.find((e) => e.id === item.boardId);
                const translation = board?.boardTranslationList.find(
                  (e) => e.langCode === i18n.language
                );

                if (translation) {
                  setModalContent({
                    text: translation,
                    imageLinks: [],
                  });
                }
              }}
            >
              <span className="text-left">
                <p className="font-bold">
                  {item.amount}x {renderBoard(item.boardId)}
                </p>
                <p className="group-hover:underline">{t('shared.more')}...</p>
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OfferBoardDisplay;
